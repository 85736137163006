import React from 'react';
import Navbar from '../componetns/navigations/Navbar';
import './MainMenu.css';
import AccountComponent from "../componetns/menu/account/AccountComponent";

function Account() {
    return (
        <div className="main_menu_container">
         <Navbar/>
            <div className="main_work_field">
                <AccountComponent />
            </div>
        </div>
    );
}

export default Account;