import React, {useRef} from 'react';


function UnitAD({attack, hp, level}) {

    const prevHP = useRef(hp);
    const prevAttack = useRef(attack);

    let hpClasses = 'unitAD unitD';
    if (prevHP.current !== hp) {
        hpClasses += ' animate__animated animate__heartBeat';
    }
    prevHP.current = hp;

    let attackClasses = 'unitAD unitA';
    if (prevAttack.current !== attack) {
        attackClasses += ' animate__animated animate__heartBeat';
    }
    prevAttack.current = attack;

    return (
        <div className={'unitADContainer unitADContainerBG-' + level}>
            <div className={attackClasses}>{attack}</div>
            <div className={hpClasses}>{hp}</div>
        </div>
    );
}

export default UnitAD;