import {
    AuthActionEnum,
    SetAuthAction,
    SetBoxesAction,
    SetCpuStoryAction,
    SetErrorAction,
    SetIsLoadingAction,
    SetMusicAction,
    SetOpenBox,
    SetPromoSocilaAction,
    SetTutorialAction,
    SetUserAction
} from "./types";
import {IUser} from "../../../models/IUser";
import {AppDispatch} from "../../index";
import UserService from "../../../api/UserService";
import {ITutorial} from "../../../models/ITutorial";
import {ICpuStory} from "../../../models/ICpuStory";
import {IMusic} from "../../../models/IMusic";
import {IBoxArray} from "../../../models/IBoxArray";

export const AuthActionCreators = {
    setUser: (user: IUser): SetUserAction => ({type: AuthActionEnum.SET_USER, payload: user}),
    setTutorial: (tutorial: ITutorial): SetTutorialAction => ({type: AuthActionEnum.SET_TUTORIAL, payload: tutorial}),
    setIsAuth: (auth: boolean): SetAuthAction => ({type: AuthActionEnum.SET_AUTH, payload: auth}),
    setIsLoading: (payload: boolean): SetIsLoadingAction => ({type: AuthActionEnum.SET_IS_LOADING, payload}),
    setError: (payload: string): SetErrorAction => ({type: AuthActionEnum.SET_ERROR, payload}),
    setCpuStory: (cpuStory: ICpuStory): SetCpuStoryAction => ({type: AuthActionEnum.SET_CPU_STORY, payload: cpuStory}),
    setMusic: (payload: IMusic): SetMusicAction => ({type: AuthActionEnum.SET_MUSIC, payload}),
    setBoxes: (payload: IBoxArray): SetBoxesAction => ({type: AuthActionEnum.SET_BOXES, payload}),
    setOpenBox: (payload: any): SetOpenBox => ({type: AuthActionEnum.SET_OPENBOX, payload}),
    setPromoSocial: (payload: boolean): SetPromoSocilaAction => ({type: AuthActionEnum.SET_PROMO_SOCIAL, payload}),


    removeCard: (cardId: number) => async (dispatch: AppDispatch) => {
        dispatch(AuthActionCreators.setIsLoading(true));
        try {
            const response = await UserService.removeCard(cardId);

            if (response.status === 200) {
                dispatch(AuthActionCreators.setUser(response.data));
            }
        } catch (e) {
            console.log('remove card error');
        }

        dispatch(AuthActionCreators.setIsLoading(false));
    },
    addCard: (cardId: number) => async (dispatch: AppDispatch) => {
        dispatch(AuthActionCreators.setIsLoading(true));

        try {
            const response = await UserService.addCard(cardId);

            if (response.status === 200) {
                dispatch(AuthActionCreators.setUser(response.data));
            }
        } catch (e) {
            console.log('add card error');
        }

        dispatch(AuthActionCreators.setIsLoading(false));


    },
    openBox: (boxId: number, callback: any) => async (dispatch: AppDispatch) => {
        dispatch(AuthActionCreators.setIsLoading(true));

        try {
            const response = await UserService.openBox(boxId);
            if (response.status === 200) {
                dispatch(AuthActionCreators.setOpenBox(response.data));
                callback();
            }
        } catch (e) {
            console.log('open box error');
        }

        dispatch(AuthActionCreators.setIsLoading(false));


    },
    getBoxes:
        () => async (dispatch: AppDispatch) => {
            {
                const response = await UserService.getBoxes();
                dispatch(AuthActionCreators.setBoxes(response.data));

            }
        },
    editUser:
        (username: string, password: string) => async (dispatch: AppDispatch) => {
            {

                const response = await UserService.editUser(username, password);
                if (response.data.user) {
                    const response = await UserService.updateUser();
                    // @ts-ignore
                    if (typeof response.data.id !== 'undefined') {
                        // @ts-ignore
                        dispatch(AuthActionCreators.setUser(response.data));
                        dispatch(AuthActionCreators.setIsAuth(true));
                    }
                    dispatch(AuthActionCreators.setError(''));
                }

                if (response.data.error !== undefined) {
                    dispatch(AuthActionCreators.setError(response.data.error));
                }

            }
        },
    updateUser:
        () => async (dispatch: AppDispatch) => {
            {
                const response = await UserService.updateUser();
                // @ts-ignore
                if (typeof response.data.id !== 'undefined') {
                    // @ts-ignore
                    dispatch(AuthActionCreators.setUser(response.data));
                    dispatch(AuthActionCreators.setIsAuth(true));
                } else {
                    localStorage.removeItem('jwtToken');
                }

            }
        },
    playNewPlayer:
        () => async (dispatch: AppDispatch) => {

            const response = await UserService.playNewPlayers();

            dispatch(AuthActionCreators.setUser(response.data));
            dispatch(AuthActionCreators.setIsAuth(true));
            dispatch(AuthActionCreators.setTutorial({isActive: true, step: 'Welcome10'}));

            localStorage.setItem('jwtToken', response.data.jwtToken);


        },
    disableTutorial:
        () => async (dispatch: AppDispatch) => {
            dispatch(AuthActionCreators.setTutorial({isActive: false, step: ''}));
        },
    login:
        (username: string, password: string) => async (dispatch: AppDispatch) => {
            try {
                dispatch(AuthActionCreators.setIsLoading(true));

                const response = await UserService.login(username, password);

                if (response) {
                    dispatch(AuthActionCreators.setUser(response.data));
                    dispatch(AuthActionCreators.setIsAuth(true));
                    localStorage.setItem('jwtToken', response.data.jwtToken);
                } else {
                    dispatch(AuthActionCreators.setError('Incorrect email or password'));
                }
                dispatch(AuthActionCreators.setIsLoading(false));

            } catch (e) {
                dispatch(AuthActionCreators.setError('Incorrect email or password'))
            }
        },
    logout:
        () => async (dispatch: AppDispatch) => {
            localStorage.removeItem('auth')
            localStorage.removeItem('jwtToken')
            dispatch(AuthActionCreators.setUser({} as IUser));
            dispatch(AuthActionCreators.setIsAuth(false))
        }
}
