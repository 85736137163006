import {GameActionCreators} from "../../../store/reducers/game/action-creators";
import {AppDispatch} from "../../../store";

export default class CoreData{

    static async cpu(data, dispatch: AppDispatch){
        dispatch(GameActionCreators.setCpu(data));
    }

    static async gameData(data, dispatch: AppDispatch){
        dispatch(GameActionCreators.setGame(data));
    }

    static async phase(data, dispatch: AppDispatch){
        dispatch(GameActionCreators.setPhase(data));
    }

    static async endGame(data, dispatch: AppDispatch){
        dispatch(GameActionCreators.setEndGame({
            winner: data.winner,
            result: data.end_game_data
        }));
    }
}