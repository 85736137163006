import './CpuTable.css';

import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import Cell from "../../table/Cell";


function CpuTable() {

    const {cpuTable} = useTypedSelector(state => state.game);

    return (
        <div className='cpuTable'>
            <Cell tableKey={0} table={cpuTable} side={'cpu'}  />
            <Cell tableKey={1} table={cpuTable} side={'cpu'} />
            <Cell tableKey={2} table={cpuTable} side={'cpu'} />
            <Cell tableKey={3} table={cpuTable} side={'cpu'} />
            <Cell tableKey={4} table={cpuTable} side={'cpu'} />

        </div>
    );
}

export default CpuTable;