import React, { useEffect } from 'react';

import '../Tutorial.css';
import HotGirl from '../../../images/login/Fox_Rogue50_2.png';
import RedArrow from '../../../images/tutorial/red_arrow.png';
import UserService from '../../../api/UserService';

function ClickBossOnMap20() {

    useEffect(() => {
      UserService.sendEvent('ClickBossOnMap20');
    }, []);

    return (
        <div>

          <div className='clickBoss20Top'>
          <img className='clickBoss20RedArrow' src={RedArrow} alt="RedArrow" />
          </div>
          <div className='clickBoss20right'></div>

          <img className='welcome10HotGirl' src={HotGirl} alt="HotGirl" />
          <div className='tutorialBottomContainer'>
              <div className='tutorialBottomTextField'>Oh Goddess, where are my manners? 
            - My name is Kailis, and now I will  show you what's here and how it works.
The are icons of location on the map. Click on the icon with the elf to continue.</div>
          </div>
        </div>
     
    );
}

export default ClickBossOnMap20;