import './PlayerHp.css';
import HP from "../../hp/HP";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";


function PlayerHp() {

    const {playerHp} = useTypedSelector(state => state.game);

    return (
        <div className='playerHP' >
            <HP hp={playerHp} side={'player'}  />
        </div>
    );
}

export default PlayerHp;