

export const ElfStory = {
    level: 1,
    name: 'Fire Elemental',
    cpu1Name: 'Earth Elemental',
    cpu2Name: 'Air Elemental',
    text: "Elves are renowned for their wisdom. Their long lifespan allows them to accumulate numerous different knowledge. But they don't share it just like that; only those who can pass deadly trials will be initiated into a small portion of what the elves know. Be clever and strong, and the secrets of true delight will be revealed to you.",
    cpu1: {
        id: 5,
        before: {
            type: 'comics',
            comicsSetting:{
                path: '/img/stories/elf/intro/',
                slideNumber: 6
            }
        },
        after: {
            player: {
                type: 'text',
                step: {
                    0: "This is only the first test, it will be more difficult later",
                }
            },
            cpu: {
                type: 'text',
                step: {
                    0: 'Well try again',
                }
            }
        }
    },
    cpu2: {
        id: 6,
        before: {
            type: 'text',
            step: {
                0: "Now let's see how you handle the element of air.",
            }
        },

        after: {
            player: {
                type: 'text',
                step: {
                    0:  "The element of air has obeyed you; let's see how you handle the fire.",
                }
            },
            cpu: {
                type: 'text',
                step: {
                    0: "The little cloud slipped from your hands. Perhaps next time you'll catch the wind.",
                }
            }
        },
    },
    cpu3: {
        id:7,
        before: {
            type: 'text',
            step: {
                0: "The element of fire is very dangerous; one can get burned in the pursuit of knowledge.",
            }
        },
        after: {
            player: {
                type: 'comics',
                comicsSetting:{
                    path: '/img/stories/elf/win/',
                    slideNumber: 32
                }
            },
            cpu: {
                type: 'text',
            step: {
                0: "Don't play with fire, but conquer it.",
            }
            }
        },
    }
}