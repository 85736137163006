import {Button, message, Space} from 'antd';
import './Deck.css';
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import Card from "../../card/Card";
import {useActions} from "../../../hooks/useActions";

function DeckComponent() {

    const {user, isLoading, tutorial} = useTypedSelector(state => state.auth);
    const {addCard, removeCard, setTutorial} = useActions();
    const [messageApi, contextHolder] = message.useMessage();
    const warning = () => {
        messageApi.open({
            type: 'warning',
            content: 'Deck must contain at least 40 cards!',
        });
    };
    console.log(user.library.cards);

    return (
        <div className="research-container">
            {contextHolder}
            <div className="deck-filter library-filter">
                <div className="deck-count">Library: {user.library.total_amount} cards</div>

            </div>
            <div className="window-slider library-slider">
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={10}
                    slidesPerView={6}
                    allowTouchMove={false}
                    loop={false}
                    navigation={{
                        prevEl: '.swiper-button-prev-lib',
                        nextEl: '.swiper-button-next-lib',
                    }}
                >
                    {Object.keys(user.library.cards).map((item, index) => {

                        return <SwiperSlide key={index + '_library'}>
                            <div className="deck_amount">Amount: {user.library.cards[item].amount}
                               </div>
                            <Card key={user.library.cards[item].card.id + '_library'}
                                  card={user.library.cards[item].card}/>

                            {((user.deck.cards[item.slice(1)] === undefined && user.library.cards[item].amount !== 0) || (user.library.cards[item].amount !== 0 && user.library.cards[item].amount !== user.deck.cards[item.slice(1)].amount && user.deck.cards[item.slice(1)].amount < 5)) ? (

                                <Button size="large" type="primary" loading={isLoading} onClick={() => {
                                    addCard(user.library.cards[item].card.id);

                                     // Tutorial
                                     if(tutorial.step === 'ClickAdd130'){
                                        tutorial.step = 'ClickHome140';
                                        setTutorial(tutorial);
                                    }
                                    // End

                                }}>
                                    Add
                                </Button>

                            ) : (
                                <div></div>
                            )
                            }


                        </SwiperSlide>;
                    })}

                </Swiper>
                <div className="swiper-button-prev swiper-button-prev-lib"></div>
                <div className="swiper-button-next swiper-button-next-lib"></div>
            </div>


            <div className="deck-filter">

                <div className="deck-count">Deck: {user.deck.total_amount} cards</div>

            </div>
            <div className="deck-slider window-slider">
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={10}
                    slidesPerView={6}
                    allowTouchMove={false}
                    loop={false}
                    navigation={{
                        prevEl: '.swiper-button-prev-deck',
                        nextEl: '.swiper-button-next-deck',
                    }}
                >

                    {Object.keys(user.deck.cards).map((item, index) => {

                        return <SwiperSlide key={index + '_deck'}>
                            <div className="deck_amount">Amount: {user.deck.cards[item].amount}</div>
                            <Card key={user.deck.cards[item].card.id + '_deck'} card={user.deck.cards[item].card}/>
                            <Button size="large" type="primary" loading={isLoading} onClick={() => {
                                removeCard(user.deck.cards[item].card.id);
                                if(user.deck.total_amount === 40){
                                    warning();
                                }
                            }}>
                                Remove
                            </Button>
                        </SwiperSlide>;
                    })}

                </Swiper>
                <div className="swiper-button-prev swiper-button-prev-deck"></div>
                <div className="swiper-button-next swiper-button-next-deck"></div>
            </div>
        </div>
    );
}

export default DeckComponent;