import React from 'react';

import './Card.css';
import ManaCost from "./ManaCost";
import UnitAD from "./UnitAD";


function Card({card, targetClasses = '', battle = false, playedCard = false}) {

    let cardRootClasses = 'cardContainer';

    let cardNameClasses = 'cardName';

    if (card.name.length > 12) {
        cardNameClasses += ' cardNameBigName';
    }
    if (battle) {
        cardRootClasses += ' cardContainerSmall';
        cardNameClasses += ' cardNameBattle';
    }

    if (playedCard) {
        cardRootClasses = ' cardContainerBig';
        cardNameClasses += ' cardNamePlayed';
    }


    return (
        <div className={cardRootClasses}>
            <div className={'rightBorderCorner rightBorderCornerBG-' + card.level}></div>
            <div className={'leftBorderCorner leftBorderCornerBG-' + card.level}></div>
            <div className={'cardNameBg cardNameBgImg-' + card.level}></div>
            <div className={targetClasses + ' cardTargetBorder'}></div>
            <div className={'cardBorder cardBorderBG-' + card.level}></div>
            <div className={cardNameClasses}>{card.name}</div>

            {(!battle) ?
                <ManaCost costs={card.cost} level={card.level} />
                :
                <div></div>
            }


            {(card.ab_text) ?
                <div className='abilityText'>

                    {(card.type_card === 'unit' && card.ab_text.length > 11) ?
                        <div className='abilityTextUnit'>{card.ab_text}</div>
                        :
                        <div className='abText'>{card.ab_text}</div>
                    }
                </div>
                :
                <div></div>
            }

            {(card.type_card === 'unit') ?
                <UnitAD attack={card.attack} hp={card.hp} level={card.level}/>
                :
                <div></div>
            }

            <img
                src={"/img/cards/mini/" + card.id + ".png"}
                className="cardImage"
                loading="eager"
            />

        </div>
    );
}

export default Card;