import {useTypedSelector} from "../../../hooks/useTypedSelector";
import Box from "./Box";
import BoxOpenWindow from "./BoxOpenWindow";
import React from "react";
import TutorialComponent from "../../tutorial/TutorialComponent";

function ShopComponent() {

    const {boxes} = useTypedSelector(state => state.auth);

    const {openBox} = useTypedSelector(state => state.auth);

    let classContainer = 'shopOpenBox';
    if(openBox !== false){
        classContainer += ' shopOpenBoxActive';
    }

    return (
        <div className='shopContainer'>
            <TutorialComponent />
            <div className="shopBoxLine">

                <Box box={boxes[0]}/>
                <Box box={boxes[1]}/>
                <Box box={boxes[2]}/>

            </div>

            <div className={classContainer}>
                {(openBox !== false) ?
                    <BoxOpenWindow openBox={openBox}/>
                    :
                    <div></div>
                }

            </div>
        </div>
    );
}

export default ShopComponent;