import React from 'react';
import {useTypedSelector} from "../../../hooks/useTypedSelector";



function MiddlewareAbility({tableKey, side, cell}) {

    const {showMiddleware} = useTypedSelector(state => state.game);

    let rootClasses = 'taeContainer';
    let taeClasses = '';
    if(showMiddleware.tableKey !== null && showMiddleware.tableKey === tableKey && showMiddleware.side === side ){
        rootClasses += ' taeContainerActive';
      //  taeClasses += 'tae animate__animated animate__tada';
        taeClasses += 'tae animate__animated animate__heartBeat';

    }

    let tae = '';
    if(cell !== 0 && cell !== null && cell.card.ability !== null){
        tae = cell.card.ability.tae;
    }

    return (
        <div className={rootClasses}  >
            <div className={taeClasses + ' ' + tae} > {tae} </div>
        </div>
    );
}

export default MiddlewareAbility;