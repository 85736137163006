import React from 'react';
import { Navigation} from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';





function Intro() {
    return (
        <Swiper
             modules={[Navigation]}
            spaceBetween={50}
            slidesPerView={1}
    
            loop={false}
            navigation={true}

        >
            {[...Array(25)].map((x, i) =>
                <SwiperSlide key={i} >
                    <img
                        src={"/img/intro/" + (i + 1) + ".png"}
                        alt="Comics"
                        className="slide-image"
                    />
                </SwiperSlide>

            )}


        </Swiper>

    );
}

export default Intro;