import ShopComponent from '../componetns/menu/shop/ShopComponent';
import Navbar from '../componetns/navigations/Navbar';
import './MainMenu.css';

function Shop() {
    return (
        <div className="main_menu_container">
         <Navbar/>
            <div className="main_work_field">

             <ShopComponent />
            </div>
        </div>
    );
}

export default Shop;