import {useEffect, useState} from 'react';
import '../componetns/landings/default/DefaultLanding.css'
import LoginFrameContainer from "../componetns/landings/default/LoginFrameContainer";
import LoginPopup from "../componetns/landings/default/LoginPopup";
import LoadingPopup from '../componetns/landings/default/LoadingPopup';
import {useActions} from "../hooks/useActions";
import {useNavigate} from "react-router-dom";
import {Preloader} from "../services/Preloader";
import {MusicService} from "../services/MusicService";

function Login() {

    const [loginPopup, setLoginPopup] = useState(false);
    const [loadingPopup, setLoadingPopup] = useState(false);
    const [loginFrame, setLoginFrame] = useState(true);


    return (
        <div className='login_container'>

            <LoginFrameContainer visible={loginFrame} setLoadingPopup={setLoadingPopup} setLoginPopup={setLoginPopup} setLoginFrame={setLoginFrame} />

            <LoginPopup visible={loginPopup} setVisible={setLoginPopup} setLoginFrame={setLoginFrame} />

            {(loadingPopup === true) ? (
            <LoadingPopup visible={loadingPopup} />
            ):(
             <div></div>
            )
           }

        </div>
    );
}

export default Login;