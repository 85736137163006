import React, { useRef} from 'react';

import './Message.css';
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";


function Message() {

    const {message} = useTypedSelector(state => state.game);
    const {setMessage} = useActions();
    let rootClasses = 'gameMessageContainer';

    if(message.text !== null && message.delay !== null){
        rootClasses += ' gameMessageContainerActive animate__animated animate__zoomIn';

        setTimeout(function(){
            setMessage({text:null, type:null, delay:null});
        }, message.delay)
    }

    return (
        <div className={rootClasses}>

            <div className={'gameMessage_' + message.type}></div>

        </div>
    );
}

export default Message;