import React from 'react';


function Sleep() {


    return (
        <div className='sleep'></div>
    );
}

export default Sleep;