export default class GeneralService {


    static makeHeader(): any {
        return {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwtToken'),
                'Content-Type': 'application/json'
            }
        }
    }
}