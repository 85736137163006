import {useState} from 'react';
import stories from './stories/stories';
import './WorldMap.css';
import BossWindowComponent from "./BossWindowComponent";
import StoryComponent from "./StoryComponent";
import {useActions} from "../../../hooks/useActions";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import PromoSocial from '../promo/PromoSocial';
import BossIconComponent from "./BossIcon/BossIconComponent";

function WorldMap() {


    const [bossWindowVisible, setBossWindowVisible] = useState(false);
    const [storyWindowVisible, setStoryWindowVisible] = useState(false);

    const {cpuStory, tutorial, user} = useTypedSelector(state => state.auth);
    const {endGame} = useTypedSelector(state => state.game);


    return (
        <div className="world_map_bg">

            <PromoSocial setBossWindowVisible={setBossWindowVisible}/>

            {(cpuStory.story !== null && cpuStory.cpuId !== null && cpuStory.action === null) ?
                <BossWindowComponent bossWindowVisible={bossWindowVisible} setBossWindowVisible={setBossWindowVisible}
                                     storyCpu={cpuStory}
                                     setStoryWindowVisible={setStoryWindowVisible}/>
                : <div></div>

            }
            {(cpuStory.story !== null && cpuStory.cpuId !== null && cpuStory.action !== null) ?
                <StoryComponent cpuStory={cpuStory} endGame={endGame} setBossWindowVisible={setBossWindowVisible}/>
                : <div></div>

            }

            <BossIconComponent story={stories.ElfStory}
                               setBossWindowVisible={setBossWindowVisible}
                               className='map-elf-ico'
            />


            <BossIconComponent story={stories.GabbyStory}
                               setBossWindowVisible={setBossWindowVisible}
                               className='map-gabby-ico'
            />


            <BossIconComponent story={stories.KailisStory}
                               setBossWindowVisible={setBossWindowVisible}
                               className='map-fox-ico'
            />

            <BossIconComponent story={stories.KailisStoryPart2}
                               setBossWindowVisible={setBossWindowVisible}
                               className='map-kailis-part2-ico'
            />

            <BossIconComponent story={stories.TowerStory}
                               setBossWindowVisible={setBossWindowVisible}
                               className='map-tower-ico'
            />

            <div className="map-ico map-discord-ico" onClick={() => {
                window.open('https://discord.gg/e5U3UnehP5', "_blank");
            }}></div>

            <div className="map-ico map-patreon-ico" onClick={() => {
                window.open('https://www.patreon.com/depravedworld', "_blank");
            }}></div>

        </div>
    );
}

export default WorldMap;