import {AppDispatch} from "../../../store";
import {GameActionCreators} from "../../../store/reducers/game/action-creators";
import ServerStateDispatcher from "../ServerStateDispatcher";
import { SoundsService } from "../../SoundsService";

export default class MiddlewareAbility {
    static async show(ServerState, dispatch: AppDispatch){


        dispatch(GameActionCreators.setShowMiddleware({tableKey:ServerState.tableKey, side: ServerState.side}));
        
        SoundsService.playMiddlewareAbility(ServerState.card_id);

        await ServerStateDispatcher.dispatchState(ServerState.result, dispatch);

        await new Promise(r => setTimeout(r, 1500));

        dispatch(GameActionCreators.setShowMiddleware({tableKey:null, side: null}));
    }
}