import '../Tutorial.css';

import FoxHead from '../../../images/tutorial/Head_fox.png';
import { Button } from 'antd';
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import UserService from '../../../api/UserService';
import { useEffect } from 'react';

function BattleFinish80() {

  const {tutorial} = useTypedSelector(state => state.auth);
    const {setTutorial} = useActions();


    useEffect(() => {
      UserService.sendEvent('BattleFinish80');
    }, []);

    return (
        <div>

          <div className='playCard40Top'>
          <img className='playCard40FoxHead' src={FoxHead} />
          <div className='playCard40Container HeadTextContainer' >
            <div className='HeadTextField'>From now on, you're on your own. Finish off the enemy!</div>
          </div>
          </div>
          <div className='BattleFinish80Button'>
                <Button size="large" type="primary" onClick={() => {
                  tutorial.isActive = false;
                   setTutorial(tutorial);
                }}>Yes!</Button>
                </div>
          <div className='playCard40right'></div>
        </div>
     
    );
}

export default BattleFinish80;