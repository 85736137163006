import {IResource} from "../../../models/game/IResource";
import {ITable} from "../../../models/game/ITable";
import {IHand} from "../../../models/game/IHand";
import {ICpuPlayedCard, IPlayedCard} from "../../../models/game/IPlayedCard";
import {IAD} from "../../../models/game/IAD";
import {IShowUnitAttack} from "../../../models/game/IShowUnitAttack";
import {IShowMiddleware} from "../../../models/game/IShowMiddleware";
import {IEndGame} from "../../../models/game/IEndGame";
import {IMessage} from "../../../models/game/IMessage";

export interface GameState {
    activeCpu: any,
    cpuName: null|string,
    game: any,
    phase: null|string,
    playerFire:IResource,
    playerDiamond:IResource,
    playerPow:IResource,
    playerHp:number,
    playerHand:IHand,
    playerTable:ITable,
    playerAD:IAD,

    cpuFire:IResource,
    cpuDiamond:IResource,
    cpuPow:IResource,
    cpuHp:number,

    cpuTable:ITable,

    playedCard:IPlayedCard,
    cpuPlayedCard: ICpuPlayedCard,

    showUnitAttack: IShowUnitAttack,
    showMiddleware: IShowMiddleware,

    endGame: IEndGame,

    message: IMessage,
}

export enum GameActionEnum {
    SET_CPU = "SET_CPU",
    SET_CPU_NAME = 'SET_CPU_NAME',
    SET_GAME = "SET_GAME",
    SET_PHASE = 'SET_PHASE',
    SET_PLAYER_FIRE = 'SET_PLAYER_FIRE',
    SET_PLAYER_DIAMOND = 'SET_PLAYER_DIAMOND',
    SET_PLAYER_POW = 'SET_PLAYER_POW',
    SET_PLAYER_HP = 'SET_PLAYER_HP',
    SET_PLAYER_TABLE = 'SET_PLAYER_TABLE',
    SET_PLAYER_HAND = 'SET_PLAYER_HAND',
    SET_PLAYER_AD = 'SET_PLAYER_AD',

    SET_CPU_FIRE = 'SET_CPU_FIRE',
    SET_CPU_DIAMOND = 'SET_CPU_DIAMOND',
    SET_CPU_POW = 'SET_CPU_POW',
    SET_CPU_HP = 'SET_CPU_HP',
    SET_CPU_TABLE = 'SET_CPU_TABLE',

    SET_PLAYED_CARD = 'SET_PLAYED_CARD',
    SET_CPU_PLAYED_CARD = 'SET_CPU_PLAYED_CARD',

    SET_SHOW_UNIT_ATTACK = 'SET_SHOW_UNIT_ATTACK',
    SET_SHOW_MIDDLEWARE = 'SET_SHOW_MIDDLEWARE',

    SET_END_GAME = 'SET_END_GAME',

    SET_MESSAGE = 'SET_MESSAGE'

}

export interface SetCpuAction {
    type: GameActionEnum.SET_CPU;
    payload: any;
}
export interface SetCpuNameAction {
    type: GameActionEnum.SET_CPU_NAME;
    payload: null|string;
}
export interface SetGameAction {
    type: GameActionEnum.SET_GAME;
    payload: any;
}
export interface SetPhaseAction {
    type: GameActionEnum.SET_PHASE;
    payload: any;
}
export interface SetPlayerFireAction {
    type: GameActionEnum.SET_PLAYER_FIRE;
    payload: IResource;
}
export interface SetPlayerDiamondAction {
    type: GameActionEnum.SET_PLAYER_DIAMOND;
    payload: IResource;
}
export interface SetPlayerPowAction {
    type: GameActionEnum.SET_PLAYER_POW;
    payload: IResource;
}
export interface SetPlayerHpAction {
    type: GameActionEnum.SET_PLAYER_HP;
    payload: number;
}
export interface SetPlayerTableAction {
    type: GameActionEnum.SET_PLAYER_TABLE;
    payload: ITable;
}
export interface SetPlayerHandAction {
    type: GameActionEnum.SET_PLAYER_HAND;
    payload: IHand;
}
export interface SetPlayerADAction {
    type: GameActionEnum.SET_PLAYER_AD;
    payload: IAD;
}



export interface SetCpuFireAction {
    type: GameActionEnum.SET_CPU_FIRE;
    payload: IResource;
}
export interface SetCpuDiamondAction {
    type: GameActionEnum.SET_CPU_DIAMOND;
    payload: IResource;
}
export interface SetCpuPowAction {
    type: GameActionEnum.SET_CPU_POW;
    payload: IResource;
}
export interface SetCpuHpAction {
    type: GameActionEnum.SET_CPU_HP;
    payload: number;
}
export interface SetCpuTableAction {
    type: GameActionEnum.SET_CPU_TABLE;
    payload: ITable;
}
export interface SetPlayedCardAction {
    type: GameActionEnum.SET_PLAYED_CARD;
    payload: IPlayedCard;
}
export interface SetCpuPlayedCardAction {
    type: GameActionEnum.SET_CPU_PLAYED_CARD;
    payload: ICpuPlayedCard;
}
export interface SetShowUnitAttackAction {
    type: GameActionEnum.SET_SHOW_UNIT_ATTACK;
    payload: IShowUnitAttack;
}
export interface SetShowMiddlewareAction {
    type: GameActionEnum.SET_SHOW_MIDDLEWARE;
    payload: IShowMiddleware;
}
export interface SetEndGameAction {
    type: GameActionEnum.SET_END_GAME;
    payload: IEndGame;
}

export interface SetMessageAction {
    type: GameActionEnum.SET_MESSAGE;
    payload: IMessage
}




export type GameAction =
    SetCpuAction |
    SetCpuNameAction |
    SetGameAction |
    SetPhaseAction |
    SetCpuTableAction |
    SetCpuHpAction |
    SetCpuPowAction |
    SetCpuDiamondAction |
    SetCpuFireAction |
    SetPlayerHandAction |
    SetPlayerTableAction |
    SetPlayerPowAction |
    SetPlayerHpAction |
    SetPlayerDiamondAction |
    SetPlayerFireAction |
    SetPlayedCardAction |
    SetPlayerADAction |
    SetCpuPlayedCardAction |
    SetShowUnitAttackAction |
    SetShowMiddlewareAction |
    SetEndGameAction |
    SetMessageAction


