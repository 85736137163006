import './AD.css';
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import {useActions} from "../../../../hooks/useActions";
import { sounds } from '../../../../sounds/sound';
import {isMobile} from 'react-device-detect';

function AD({tableKey}) {

    const {phase, playerAD} = useTypedSelector(state => state.game);
    const {tutorial} = useTypedSelector(state => state.auth);
    const {setPlayerAD,setTutorial} = useActions();

    let ADFormat = "Normal";

    if(isMobile){
        ADFormat = 'Mobile';
    }

    let adClasses = 'adTable';
    if(phase === 'play_card'){
        adClasses += ' adTableActive';
    }

    let attackClasses = 'adAttack';
    if(playerAD[tableKey]){
        attackClasses += ' adAttackActive adAttackActive' + ADFormat;
    }else{
        attackClasses += ' adAttackPassive adAttackPassive' + ADFormat;
    }
    let defenceClasses = 'adDefence';
    if(!playerAD[tableKey]){
        defenceClasses += ' adDefenceActive adDefenceActive' + ADFormat;
    }else{
        defenceClasses += ' adDefencePassive adDefencePassive' + ADFormat;
    }

    const setAttack = function(){
        playerAD[tableKey] = true;
        setPlayerAD(playerAD);


        sounds.battle_unit_set_attack().play();

        // Tutorial
        if(tutorial.step === 'SelectAttack60'){
            tutorial.step = 'EndTurnBattle65';
            setTutorial(tutorial); 
        }
        //
    }

    const setDefence= function(){
        playerAD[tableKey] = false;
        setPlayerAD(playerAD);

        sounds.battle_unit_set_defence().play();
    }

    return (
        <div className={adClasses}>
            <div className={attackClasses} onClick={() =>{ setAttack(); }} ></div>
            <div className={defenceClasses} onClick={() =>{ setDefence(); }} ></div>
        </div>
    );
}

export default AD;