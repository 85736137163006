import React, { useEffect } from 'react';

import '../Tutorial.css';
import RedArrow from '../../../images/tutorial/red_arrow.png';
import UserService from '../../../api/UserService';

function ElfComics35() {

  useEffect(() => {
    UserService.sendEvent('ElfComics35');
  }, []);


    return (
        <div>
          <img className='ElfComics35RedArrow' src={RedArrow} />
        </div>
     
    );
}

export default ElfComics35;