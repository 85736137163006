import React from 'react';
import Navbar from '../componetns/navigations/Navbar';
import './MainMenu.css';
import ResearchesComponent from "../componetns/menu/researches/ResearchesComponent";

function Researches() {
    return (
        <div className="main_menu_container">
         <Navbar/>
            <div className="main_work_field">
                <ResearchesComponent />
            </div>
        </div>
    );
}

export default Researches;