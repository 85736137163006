import React, { useEffect } from 'react';

import '../Tutorial.css';
import RedArrow from '../../../images/tutorial/red_arrow.png';
import FoxHead from '../../../images/tutorial/Head_fox.png';
import { Button } from 'antd';
import { useActions } from '../../../hooks/useActions';
import UserService from '../../../api/UserService';

function SpellTarget75() {

  useEffect(() => {
    UserService.sendEvent('SpellTarget75');
  }, []);

    return (
        <div>

          <div className='SpellTarget75Top'>
          <img className='playCard40FoxHead' src={FoxHead} />
          <div className='playCard40Container HeadTextContainer' >
            <div className='HeadTextField'>Select HP target</div>
          </div>
          </div>
          <div className='SpellTarget75right'></div>
          <img className='SpellTarget75RedArrow' src={RedArrow} />
        </div>
     
    );
}

export default SpellTarget75;