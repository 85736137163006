// @ts-ignore
import musicImageON from "../../images/main_menu/menu_button_music_on.png"
// @ts-ignore
import musicImageOFF from "../../images/main_menu/menu_button_music_off.png"
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { MusicService } from "../../services/MusicService";
import { useActions } from "../../hooks/useActions";
function MusicButton(){
    const {music} = useTypedSelector(state => state.auth);
    const {setMusic} = useActions();

    let musicImage = musicImageON;

    if(music.playMenu === false){
        musicImage = musicImageOFF;
    }

    const OnOfMusic = function(){

        if(music.playMenu === true){
            music.playMenu = false;
            MusicService.stopMenu();
            
        }else{
            music.playMenu = true;
            MusicService.playMenuMusic();
        }
        setMusic(music);
    };

    return (
        <div className="main_menu_gold" title="On\Off Music"><img className="menu_music_image" src={musicImage}
        onClick={() => { OnOfMusic();}}
        /></div>
    );
}

export default MusicButton;