import {AppDispatch} from "../../../store";
import {GameActionCreators} from "../../../store/reducers/game/action-creators";


export default class Resource{
    static async set(side, resource, value, dispatch: AppDispatch){

        let method = 'set' + side + resource;

        dispatch(GameActionCreators[method](value));

    }
}

