
import {useActions} from "../../../../hooks/useActions";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";

function BossIconComponent({story, setBossWindowVisible, className}) {

    const {user, tutorial} = useTypedSelector(state => state.auth);

    const {setCpuStory, setTutorial} = useActions();

    const tutorialAction = function () {
        if (tutorial.step === 'ClickBossOnMap20') {
            tutorial.step = 'BossWindow30';
            setTutorial(tutorial);
        }
    }

    className += ' map-ico';

    if(story.level > user.level){
        className += ' map-ico-gray';
    }

    let classNameStar1 = 'map_star_bg_empty';
    if(user.maps.find((element: any) => element === story.cpu1.id)){
        classNameStar1 = 'map_star_bg_filled';
    }
    let classNameStar2 = 'map_star_bg_empty';
    if(user.maps.find((element: any) => element === story.cpu2.id)){
        classNameStar2 = 'map_star_bg_filled';
    }
    let classNameStar3 = 'map_star_bg_empty';
    if(user.maps.find((element: any) => element === story.cpu3.id)){
        classNameStar3 = 'map_star_bg_filled';
    }

    return (
        <div className={className} onClick={() => {
            tutorialAction();
            setCpuStory({
                story: story,
                cpuId: story.cpu1.id,
                cpuNumber: 'cpu1',
                action: null
            });
            setBossWindowVisible(true);
        }}>
            <div className={'map_star map_star_1 ' + classNameStar1}></div>
            <div className={'map_star map_star_2 ' + classNameStar2}></div>
            <div className={'map_star map_star_3 ' + classNameStar3}></div>
        </div>
    );
}

export default BossIconComponent;