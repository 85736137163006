export let CanBePlayedCard;
CanBePlayedCard = function (card, playerFire, playerPow, playerDiamond, playerTable, cpuTable) {
    let canBePlayed = true;

    // check mana cost
    for (const [type, value] of Object.entries(JSON.parse(card.cost))) {

        // @ts-ignore
        if (type === 'pow' && playerPow.amount < value) {
            canBePlayed = false;
        }
        // @ts-ignore
        if (type === 'diamond' && playerDiamond.amount < value) {
            canBePlayed = false;
        }
        // @ts-ignore
        if (type === 'fire' && playerFire.amount < value) {
            canBePlayed = false;
        }
    }

    // check free place for unit
    if (card.type_card === 'unit' && canBePlayed) {
        canBePlayed = false;
        for (let i = 0; i < 5; i++) {

            if (playerTable[i] === null || playerTable[i] === 0) {
                canBePlayed = true;
            }
        }
    }

    // it has target
    if (card.type_card === 'spell' && canBePlayed && card.ability.aim_target === 'unit') {
        canBePlayed = false;
        let table = cpuTable;
        if (card.ability.side_target === 'player') {
            table = playerTable;
        }
        for (let i = 0; i < 5; i++) {
            if (table[i] !== null && table[i] !== 0) {
                canBePlayed = true;
            }
        }
    }

    return canBePlayed;
}