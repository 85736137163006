

export const GabbyStory = {
    level: 2,
    name: 'Gabriela',
    cpu1Name: 'Zombie horde',
    cpu2Name: 'Zombie wizard',
    text: 'Gabriela a creature created by magic, half human, half beast. Initially, such creatures were bred for sexual pleasures and for rites of pleasure to the glory of the goddess. But over time, they began to flee from slavery and settle around the world. Since they are of magical origin, they are the strongest mages. When Gabriela escaped from sex slavery, she settled in the forest north of the Free City. Where she helps his own kind and gives them shelter. Gabriela has great abilities in necromancy and the forest is guarded by countless hordes of zombies. Not everyone decides to enter there, and no one has seen those who entered the forest.',
    cpu1: {
        id: 2,
        before: {
            type: 'comics',
            comicsSetting:{
                path: '/img/stories/gabby/intro/',
                slideNumber: 25
            }
        },
        after: {
            player: {
                type: 'text',
                step: {
                    0: 'Hmm... You are interesting.',
                }
            },
            cpu: {
                type: 'text',
                step: {
                    0: 'Wow scary zombies!',
                }
            }
        }
    },
    cpu2: {
        id: 3,
        before: {
            type: 'text',
            step: {
                0: 'Let\'s add some magic to your walk through the dark forest',
            }
        },

        after: {
            player: {
                type: 'text',
                step: {
                    0: 'If you want to do something right, do it yourself',
                }
            },
            cpu: {
                type: 'text',
                step: {
                    0: 'Magic is a serious power.',
                }
            }
        },
    },
    cpu3: {
        id:4,
        before: {
            type: 'text',
            step: {
                0: 'Well, well, well. A boy with a cute ass came to visit us. I will make you  a very deep penetration that the goddess will hear your cries.',
            }
        },
        after: {
            player: {
                type: 'comics',
                comicsSetting:{
                    path: '/img/stories/gabby/win/',
                    slideNumber: 8
                }
            },
            cpu: {
                type: 'comics',
                comicsSetting:{
                    path: '/img/stories/gabby/lose/',
                    slideNumber: 6
                }
            }
        },
    }
}