import React, { useEffect } from 'react';

import '../Tutorial.css';
import HotGirl from '../../../images/login/Fox_Rogue50_2.png';
import { Button } from 'antd';
import { useActions } from '../../../hooks/useActions';
import UserService from '../../../api/UserService';

function Welcome10() {

    const {setTutorial} = useActions();

    useEffect(() => {
        UserService.sendEvent('Welcome10');
      }, []);

    return (
        <div className='welcome10Container'>
            
          <img className='welcome10HotGirl' src={HotGirl} alt="" />
          <div className='tutorialBottomContainer'>
              <div className='tutorialBottomTextField'> Welcome to the Depraved World!<br/>
The land of true delight given to us by the Goddess. Learn, conquer, be vibrant and emotional, and the Goddess will grant you so much strength and might that the whole world will bow at your feet.</div>
              <div className='welcome10NextButton' >
              <Button size="large" type="primary" onClick={() => {
                        setTutorial({isActive:true, step:'ClickBossOnMap20'});
                        }}>
                            Next
                        </Button>
              </div>
          </div>
        </div>
     
    );
}

export default Welcome10;