import { ITutorial } from "../../../models/ITutorial";
import {IUser} from "../../../models/IUser";
import {ICpuStory} from "../../../models/ICpuStory";
import {IMusic} from "../../../models/IMusic";
import {IBox} from "../../../models/IBox";
import {IBoxArray} from "../../../models/IBoxArray";

export interface AuthState {
    isAuth: boolean;
    user: IUser;
    isLoading: boolean;
    error: string;
    tutorial: ITutorial;
    cpuStory: ICpuStory;
    music: IMusic;
    boxes: IBoxArray;
    openBox: any;
    promoSocial: boolean; // promo popup on map
}

export enum AuthActionEnum {
    SET_IS_LOADING = "SET_IS_LOADING",
    SET_AUTH = "SET_AUTH",
    SET_ERROR = "SET_ERROR",
    SET_USER = "SET_USER",
    SET_TUTORIAL = "SET_TUTORIAL",
    SET_CPU_STORY = "SET_CPU_STORY",
    SET_MUSIC = "SET_MUSIC",
    SET_BOXES = "SET_BOXES",
    SET_OPENBOX = 'SET_OPENBOX',
    SET_PROMO_SOCIAL = "SET_PROMO_SOCIAL"
}

export interface SetAuthAction {
    type: AuthActionEnum.SET_AUTH;
    payload: boolean;
}
export interface SetErrorAction {
    type: AuthActionEnum.SET_ERROR;
    payload: string;
}
export interface SetUserAction {
    type: AuthActionEnum.SET_USER;
    payload: IUser;
}
export interface SetTutorialAction {
    type: AuthActionEnum.SET_TUTORIAL;
    payload: ITutorial;
}
export interface SetIsLoadingAction {
    type: AuthActionEnum.SET_IS_LOADING;
    payload: boolean;
}
export interface SetCpuStoryAction {
    type: AuthActionEnum.SET_CPU_STORY;
    payload: ICpuStory;
}

export interface SetMusicAction {
    type: AuthActionEnum.SET_MUSIC,
    payload: IMusic;
}

export interface SetBoxesAction {
    type: AuthActionEnum.SET_BOXES,
    payload: IBoxArray;
}

export interface SetOpenBox {
    type: AuthActionEnum.SET_OPENBOX,
    payload: any
}

export interface SetPromoSocilaAction {
    type: AuthActionEnum.SET_PROMO_SOCIAL,
    payload: boolean;
}

export type AuthAction =
    SetAuthAction |
    SetUserAction |
    SetErrorAction |
    SetIsLoadingAction |
    SetTutorialAction |
    SetCpuStoryAction |
    SetMusicAction |
    SetBoxesAction |
    SetOpenBox |
    SetPromoSocilaAction
