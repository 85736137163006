import {GameAction, GameActionEnum, GameState} from "./types";


const initialState: GameState = {
    activeCpu: null,
    cpuName:null,
    game: null,
    phase: null,
    playerFire: {inc:0, amount:0},
    playerDiamond:{inc:0, amount:0},
    playerPow:{inc:0, amount:0},
    playerHp:0,
    playerHand:[],
    playerTable: {0: null, 1:null, 2:null, 3:null, 4:null},
    playerAD: {0: false, 1:false, 2:false, 3:false, 4:false},

    cpuFire:{inc:0, amount:0},
    cpuDiamond:{inc:0, amount:0},
    cpuPow:{inc:0, amount:0},
    cpuHp:0,
    cpuTable:{0: null, 1:null, 2:null, 3:null, 4:null},
    playedCard : {card:null, handKey:null,target:null},
    cpuPlayedCard : {card:null},

    showUnitAttack: {tableKey:null, side:null},
    showMiddleware:{tableKey:null, side:null},

    endGame: {winner:null,result:null},

    message:{text:null, type:null, delay:null}
}

export default function authReducer(state = initialState, action: GameAction): GameState {
    switch (action.type) {
        case GameActionEnum.SET_CPU:
            return {...state, activeCpu: action.payload}
        case GameActionEnum.SET_CPU_NAME:
            return {...state, cpuName: action.payload}
        case GameActionEnum.SET_GAME:
            return {...state, game: action.payload}
        case GameActionEnum.SET_PHASE:
            return {...state, phase: action.payload}
        case GameActionEnum.SET_PLAYER_FIRE:
            return {...state, playerFire: action.payload}
        case GameActionEnum.SET_PLAYER_DIAMOND:
            return {...state, playerDiamond: action.payload}
        case GameActionEnum.SET_PLAYER_POW:
            return {...state, playerPow: action.payload}
        case GameActionEnum.SET_PLAYER_HP:
            return {...state, playerHp: action.payload}
        case GameActionEnum.SET_PLAYER_TABLE:
            return {...state, playerTable: action.payload}
        case GameActionEnum.SET_PLAYER_HAND:
            return {...state, playerHand: action.payload}
        case GameActionEnum.SET_CPU_FIRE:
            return {...state, cpuFire: action.payload}
        case GameActionEnum.SET_CPU_DIAMOND:
            return {...state, cpuDiamond: action.payload}
        case GameActionEnum.SET_CPU_POW:
            return {...state, cpuPow: action.payload}
        case GameActionEnum.SET_CPU_TABLE:
            return {...state, cpuTable: action.payload}
        case GameActionEnum.SET_CPU_HP:
            return {...state, cpuHp: action.payload}
        case GameActionEnum.SET_PLAYED_CARD:
            return {...state, playedCard: action.payload}
        case GameActionEnum.SET_CPU_PLAYED_CARD:
            return {...state, cpuPlayedCard: action.payload}
        case GameActionEnum.SET_PLAYER_AD:
            return {...state, playerAD: action.payload}
        case GameActionEnum.SET_SHOW_UNIT_ATTACK:
            return {...state, showUnitAttack: action.payload}
        case GameActionEnum.SET_SHOW_MIDDLEWARE:
            return {...state, showMiddleware: action.payload}
        case GameActionEnum.SET_END_GAME:
            return {...state, endGame: action.payload}
        case GameActionEnum.SET_MESSAGE:
            return {...state, message: action.payload}

        default:
            return state;
    }
}
