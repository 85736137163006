import './DefaultLanding.css'
import {useActions} from "../../../hooks/useActions";
import {useNavigate} from 'react-router-dom';
import {MusicService} from '../../../services/MusicService';
import {Preloader} from '../../../services/Preloader';

// @ts-ignore
const LoginFrameContainer = ({visible, setLoginPopup, setLoginFrame, setLoadingPopup}) => {
    const navigate = useNavigate();
    const {playNewPlayer} = useActions()
    const {updateUser, getBoxes} = useActions();

    const rootClasses = ['login-frame-container'];

    if (!visible) {
        rootClasses.push('login-frame-container-deactivate');
    }

    return (
        <div className={rootClasses.join(' ')}>

            <div className="login-frame">
                <div className="login-frame-text">

                    <h1 className='login-frame-logo'>Depraved world</h1>
                    <p>
                        "Depraved World: Unleash Your Desires in this 18+ Fantasy Realm"
                        Embark on an enticing journey in "Depraved World," an online game designed exclusively for
                        adults aged 18 and above. Immerse yourself in a captivating fantasy world populated by
                        passionate furry characters, where pleasure reigns supreme.
                        Join the ranks of adventurers in this free browser game, engaging in passionate battles that
                        earn glory for the goddess and unlock unprecedented power. The game features immersive card
                        battles, where strategic decisions shape your destiny.
                    </p>
                </div>
            </div>

            <div className="sign-up-button" onClick={() => {
                setLoginFrame(false);
                setLoadingPopup(true);
                Preloader.load();
                if (localStorage.getItem('jwtToken')) {

                    setTimeout(() => {
                        updateUser();
                        setLoadingPopup(false);
                        setLoginFrame(true);
                        navigate('/');
                        MusicService.playMenuMusic();
                    }, 9000);
                } else {
                    setTimeout(() => {

                        playNewPlayer();
                        navigate('/');
                        MusicService.playTutorial();
                    }, 9000);
                }
                setTimeout(() => {

                    getBoxes();

                }, 10000);

            }}> Play
            </div>

            <div className="sign-in-button" onClick={() => {
                setLoginPopup(true);
                setLoginFrame(false)
            }}>Sign In
            </div>
        </div>
    );
};

export default LoginFrameContainer;