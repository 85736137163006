import React, { useRef} from 'react';

import './Resources.css';


function Resources({cssClasses, resource}) {


    return (
        <div className={cssClasses}>
            <div className='resourceAmount'>{resource.amount}</div>
            <div className='resourceInc'>+{resource.inc}</div>
        </div>
    );
}

export default Resources;