export const KailisStoryPart2 = {
    level: 4,
    name: 'Kailis Heretic',
    cpu1Name: 'Chimera',
    cpu2Name: 'Lamia',
    text: 'The city honors Kailis; aristocrats do not take the former courtesan seriously. Having a brothel and wealth, she is the head of the guild of Shadows. Deciding to take part in the competition for entry into the Circle of Nine, she learns from the old magician. They travel to the catacombs beneath the city and encounter a priestess who has offered power and knowledge in exchange for sacrifice. Kailis sacrificed a teacher and received a magical amulet that increased her strength, but needs new victims. In the Citadel of the Goddess of Pleasure, the  Priestess felt an incredible flash of magical power, this indicates the return of the forgotten God.',
    cpu1: {
        id: 12,
        before: {
            type: 'comics',
            comicsSetting: {
                path: '/img/stories/kailis_heretic/intro/',
                slideNumber: 15
            }
        },
        after: {
            player: {
                type: 'text',
                step: {
                    0: "So you're fast, but it's not the end yet.",
                }
            },
            cpu: {
                type: 'text',
                step: {
                    0: 'Ha! You are not fast enough!',
                }
            }
        }
    },
    cpu2: {
        id: 13,
        before: {
            type: 'text',
            step: {
                0: 'The one true God has many followers. Time will get acquainted with them.',
            }
        },

        after: {
            player: {
                type: 'text',
                step: {
                    0: "Not all followers of the god of death are worthy of him. Thank you for removing the weak.",
                }
            },
            cpu: {
                type: 'text',
                step: {
                    0: 'The followers of the pitiful goddess of pleasure are so weak.',
                }
            }
        }
    },
    cpu3: {
        id: 14,
        before: {
            type: 'text',
            step: {
                0: 'The time of the decisive battle has come; now we will find out whose god is stronger.',
            }
        },
        after: {
            cpu: {
                type: 'text',
                step: {
                    0: 'Nothing surprising, the weak lose, the strong win.',
                }
            },
            player: {
                type: 'comics',
                comicsSetting: {
                    path: '/img/stories/kailis_heretic/win/',
                    slideNumber: 10
                }
            }
        },

    },
}
