import { ICard } from "../models/card/ICard";
import { sounds } from "../sounds/sound";
import { SoundsCardMap } from "../sounds/SoundsCardMap";
import { SoundsMiddlewareMap } from "../sounds/SoundsMiddlewareMap";
import { store } from "../store";


export const SoundsService = {

    playMiddlewareAbility: function(cardId: number){

        let cardSound = sounds.battle_play_spell();

        if(SoundsMiddlewareMap.hasOwnProperty(cardId)){
            cardSound = SoundsMiddlewareMap[cardId]();
        }

        cardSound.play();
    },

    playCard: function(card: ICard | null, handKey: any = null){

    if(card === null){

        const state = store.getState();
        let playerHand = state.game.playerHand;

        if(handKey !== null && playerHand[handKey] !== null){
            card = playerHand[handKey];
        }else{
           return;
        }
    }
    if(card === null){
        return;
    }

    let cardSound = SoundsService.getSoundByCardId(card.id);

    if(cardSound !== false){
        cardSound.play();
        return;
    }
  
     if(card.type_card === 'spell'){
        sounds.battle_play_spell().play();
     }

     if(card.type_card === 'unit'){
        sounds.battle_play_unit().play();
     }

    },

    getSoundByCardId: function(cardId: any){
        if(SoundsCardMap.hasOwnProperty(cardId)){
            return SoundsCardMap[cardId]();
        }
      return false;  
    }

}