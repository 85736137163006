import {useActions} from "./hooks/useActions";
import 'animate.css';
import './App.css';
import AppRouter from "./router/AppRouter";
import { MusicService } from "./services/MusicService";
import {isMobile} from 'react-device-detect';
import {Preloader} from "./services/Preloader";
import {useNavigate} from "react-router-dom";


function App() {


    let coreClass = "App";

    if(isMobile){
        coreClass = 'AppMobile';
    }

   // console.log(window.screen.orientation.type);
    /*     window.screen.orientation.type
    "portrait-primary"
"portrait-secondary"
"landscape-primary"
"landscape-secondary"
    */

    return (
        <div className={coreClass}>

            <AppRouter/>

        </div>
    );
}

export default App;
