import '../Tutorial.css';
import RedArrow from '../../../images/tutorial/red_arrow.png';
import FoxHead from '../../../images/tutorial/Head_fox.png';
import { useEffect } from 'react';
import UserService from '../../../api/UserService';

function ClickHome140() {

  useEffect(() => {
    UserService.sendEvent('ClickHome140');
  }, []);

    return (
      <div>
         <img className='MenuFoxHead' src={FoxHead} />
          <div className='MenuFoxHeadContainer HeadTextContainer' >
            <div className='HeadTextField'>Press menu button</div>
          </div>   
              
       <div className='menuResearch90Bottom'>
         <img className='ClickHome140RedArrow' src={RedArrow} />
     </div>
    
      <div className='ClickHome140Left'></div>
    </div>
     
    );
}

export default ClickHome140;