// @ts-ignore
import musicImageON from "../../images/main_menu/battle_button_music_on.png"
// @ts-ignore
import musicImageOFF from "../../images/main_menu/battle_button_music_off.png"
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { MusicService } from "../../services/MusicService";
import { useActions } from "../../hooks/useActions";
function MusicBattleButton(){
    const {music} = useTypedSelector(state => state.auth);
    const {setMusic} = useActions();

    let musicImage = musicImageON;

    if(music.playBattle === false){
        musicImage = musicImageOFF;
    }

    const OnOfMusic = function(){

        if(music.playBattle === true){
            music.playBattle = false;
            MusicService.stopBattle();
            
        }else{
            music.playBattle = true;
            MusicService.playBattleMusic();
        }
        setMusic(music);
    };

    return (
        <div className="musicButton" title="On\Off Music"><img className="menu_music_image" src={musicImage}
        onClick={() => { OnOfMusic();}}
        /></div>
    );
}

export default MusicBattleButton;