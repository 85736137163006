import React from 'react';

import './GamePage.css';
import GameComponent from "../componetns/game/GameComponent";

function GamePage() {
    return (
        <div className="game-container">

        <GameComponent />

        </div>
    );
}

export default GamePage;