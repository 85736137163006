import '../Tutorial.css';
import RedArrow from '../../../images/tutorial/red_arrow.png';
import FoxHead from '../../../images/tutorial/Head_fox.png';
import { useEffect } from 'react';
import UserService from '../../../api/UserService';

function ClickAdd130() {

  useEffect(() => {
    UserService.sendEvent('ClickAdd130');
  }, []);

    return (
      <div>
<img className='MenuFoxHead' src={FoxHead} />
          <div className='MenuFoxHeadContainer HeadTextContainer' >
            <div className='HeadTextField'>Add card to deck</div>
          </div> 
       <div className='ClickBuy120Top'></div>
       <img className='ClickBuy120RedArrow' src={RedArrow} />
      <div className='ClickBuy120Bottom'></div>
     
    </div>
     
    );
}

export default ClickAdd130;