import './GameComponent.css';
import PlayerResources from "./player/resources/PlayerResources";
import CpuResources from "./cpu/resources/CpuResources";
import PlayerHp from "./player/hp/PlayerHp";
import CpuHp from "./cpu/hp/CpuHp";
import PlayerHand from "./player/hand/PlayerHand";
import PlayerTable from "./player/table/PlayerTable";
import CpuTable from "./cpu/table/CpuTable";
import EndTurnButton from "./endturn/EndTurnButton";
import PlayedCard from "./playedcard/PlayedCard";
import PlayerName from "./player/name/PlayerName";
import CpuName from "./cpu/name/CpuName";
import EndGame from "./end_game/EndGame";
import Message from "./message/Message";
import { useTypedSelector } from '../../hooks/useTypedSelector';
import TutorialComponent from '../tutorial/TutorialComponent';
import MusicBattleButton from './MusicBattleButton';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function GameComponent() {
    let navigate = useNavigate();
    const {game} = useTypedSelector(state => state.game);
     
   
    useEffect(() => {
        if(game === null){
            navigate('/home');
        }
      });
    return (
        <div className='gameComponentContainer'>

            <Message/>

            <CpuName/>
            <MusicBattleButton />
            <CpuHp/>
            <CpuResources/>

            <PlayerName />
            <PlayerHp/>
            <PlayerResources/>

            <div className='gameBattlefieldContainer'>
                <div className='gameBattlefield'>
                    <CpuTable/>
                    <PlayerTable/>
                </div>
            </div>

            <PlayedCard />
            <EndTurnButton/>

            <PlayerHand/>

            <EndGame />
            
            <TutorialComponent />

        </div>
    );
}

export default GameComponent;