import React, {useRef} from 'react';

import './HP.css';
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";
import Damage from "../damage/Damage";


function HP({hp, side}) {

    const prev = useRef(hp)

    let rootClass = 'hp';
    if (prev.current !== hp) {
        // hp - now
        //prev.current - last
        rootClass += ' animate__animated animate__bounce';
    }
    prev.current = hp;


    const {phase, playedCard} = useTypedSelector(state => state.game);
    const {tutorial} = useTypedSelector(state => state.auth);
    const {playCard, setPlayedCard, setPhase, setTutorial} = useActions();

    let targetClasses = '';
    let showPlaySpellTarget = false;

    if (phase === 'select_spell_target'
        && playedCard.target === null
        && side === playedCard.card?.ability.side_target
        && (playedCard.card?.ability.aim_target === 'castle' || playedCard.card?.ability.aim_target === 'unit_castle')
    ) {
        targetClasses += ' hpTargetActive';
        showPlaySpellTarget = true;
    }
    const setTargetAndSpellUnit = function () {

        // Tutorial
          if(tutorial.step === 'SpellTarget75'){
            tutorial.step = 'BattleFinish80';
            setTutorial(tutorial);
          }
        //


        if (side === 'cpu') {
            playedCard.target = {key: 'target_cpu_hp', value: '1'};
        } else {
            playedCard.target = {key: 'target_player_hp', value: '1'};
        }
        playCard(playedCard);

        setPlayedCard({card: null, handKey: null, target: null});
        setPhase('play_spell');
    }


    return (
        <div className={'hpContainer'  + targetClasses}>
            <Damage />
            <div className={rootClass}
                 onClick={() => {
                     if (showPlaySpellTarget) {
                         setTargetAndSpellUnit();
                     }
                 }}
            >HP: {hp}</div>
        </div>
    );
}

export default HP;