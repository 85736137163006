import React from 'react';

import './PlayerResources.css';
import Resource from "../../resource/Resource";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";

function PlayerResources() {

    const {playerFire, playerPow, playerDiamond} = useTypedSelector(state => state.game);


    return (
        <div className='playerResources'>
            <Resource cssClasses={'resource fireResource'} resource={playerFire} />
            <Resource cssClasses={'resource diamondResource'} resource={playerDiamond} />
            <Resource cssClasses={'resource powResource'} resource={playerPow} />
        </div>
    );
}

export default PlayerResources;