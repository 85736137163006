import './BossWindow.css';
import './Cpu.css';
import {Button, message} from "antd";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";


function BossWindowComponent({
                                 bossWindowVisible,
                                 setBossWindowVisible,
                                 storyCpu,
                                 setStoryWindowVisible
                             }) {

    const {user, tutorial} = useTypedSelector(state => state.auth);
    const [messageApi, contextHolder] = message.useMessage();
    const {setCpuStory, setTutorial} = useActions();

    const warning = () => {
        messageApi.open({
            type: 'warning',
            content: 'Deck must contain at least 40 cards!',
        });
    };

    const setActiveCpu = (cpuId, cpuNumber) => {
        setCpuStory({
            story: storyCpu.story,
            cpuId: cpuId,
            cpuNumber:cpuNumber,
            action: null
        });
    }

    const story = storyCpu.story;

    let rootCpu1Classes = 'map-popup-cpu-image ' + 'cpu-' + story.cpu1.id;
    let rootCpu2Classes = 'map-popup-cpu-image' + ' cpu-' + story.cpu2.id;
    let rootCpu3Classes = 'map-popup-cpu-image ' + 'cpu-' + story.cpu3.id;
    if (storyCpu.cpuId === story.cpu1.id) {
        rootCpu1Classes += ' map-popup-selected-cpu';
    }
    if (storyCpu.cpuId === story.cpu2.id) {
        rootCpu2Classes += ' map-popup-selected-cpu';
    }
    if (storyCpu.cpuId === story.cpu3.id) {
        rootCpu3Classes += ' map-popup-selected-cpu';
    }

    const rootClasses = ['map-popup'];

    if (bossWindowVisible) {
        rootClasses.push('map-popup-active');
    }

    const tutorialAction = function(){
        if(tutorial.step === 'BossWindow30'){
         tutorial.step = 'ElfComics35';
         setTutorial(tutorial);
        }
     }

    let cpu3Name = story.name;
    if(story.cpu3Name !== undefined){
        cpu3Name = story.cpu3Name;
    }

    return (
        <div className={rootClasses.join(' ')}>
            {contextHolder}
            <a href="#" className="login-sign-in-close" onClick={() => {
                setBossWindowVisible(false)
            }}></a>

            <div className="map-popup-caption">{story.name}</div>
            { story.level <= user.level ? (
            <div className="map-popup-text">{story.text}
            </div>
            ): (
                <div className="map-popup-text-level">Required level {story.level}
                </div>
            )

            }

            <div className="map-popup-cpu-caption">Choice your enemy:</div>
            <div className="map-popup-cpu-container">
                <div className="map-popup-cpu" onClick={() => {
                    setActiveCpu(story.cpu1.id, 'cpu1');
                }}>
                    <div className={rootCpu1Classes}></div>
                    <div className="map-popup-cpu-text">{story.cpu1Name}</div>
                </div>

                <div className="map-popup-arrow">
                    <i className="arrow right"></i>
                    <i className="arrow right"></i>
                    <i className="arrow right"></i>
                </div>

                <div
                    className={'map-popup-cpu'}
                    onClick={() => {
                        if (user.maps.find(element => element === story.cpu1.id)) {
                            setActiveCpu(story.cpu2.id, 'cpu2');
                        }
                    }}
                >
                    {user.maps.find(element => element === story.cpu1.id) ?<div></div> : <div className="map-popup-lock"></div>}
                    <div className={rootCpu2Classes}></div>
                    <div className="map-popup-cpu-text">{story.cpu2Name}</div>
                </div>

                <div className="map-popup-arrow">
                    <i className="arrow right"></i>
                    <i className="arrow right"></i>
                    <i className="arrow right"></i>
                </div>

                <div
                    className={'map-popup-cpu'}
                    onClick={() => {
                        if (user.maps.find(element => element === story.cpu2.id)) {
                            setActiveCpu(story.cpu3.id, 'cpu3');
                        }
                    }}
                >
                    {user.maps.find(element => element === story.cpu2.id) ?<div></div> : <div className="map-popup-lock"></div>}
                    <div className={rootCpu3Classes}></div>
                    <div className="map-popup-cpu-text">{cpu3Name}</div>
                </div>
            </div>


            <div className="map-popup-play-button">
                {story.level <= user.level ? (
                    user.deck.total_amount >= 40 ? (
                        <Button size="large" type="primary" onClick={() => {
                            tutorialAction();
                            storyCpu.action = 'before';
                            setCpuStory(storyCpu);
                            setBossWindowVisible(false);
                        }}>
                            Play
                        </Button>
                    ) : (

                        <Button size="large" type="primary" onClick={() => {
                            warning()
                        }}>
                            Play({user.deck.total_amount} card in deck)
                        </Button>
                    )

                ) : (
                    <Button size="large" type="primary"
                    onClick={() => {
                        window.open('https://www.patreon.com/depravedworld', "_blank");
                    }}
                    >
                        Support
                    </Button>
                )
                }
            </div>


        </div>
    );
}

export default BossWindowComponent;