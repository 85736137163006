import {GabbyStory} from './gabby/GabbyStory'
import {KailisStory} from "./kailis/KailisStory";
import {ElfStory} from "./elf/ElfStory";
import { KailisStoryPart2 } from './kailis/KailisStoryPart2';
import {TowerStory} from "./tower/TowerStory";


export default {
    GabbyStory,
    KailisStory,
    ElfStory,
    KailisStoryPart2,
    TowerStory
}
