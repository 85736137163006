import {useState} from 'react';
import './Account.css';
import {Button, Form, Input} from "antd";
import {rules} from "../../../utils/rules";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";


function AccountComponent() {

    const {error, isLoading, user} = useTypedSelector(state => state.auth);
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const {editUser, logout, setError} = useActions()


    const submit = () => {
        setError('');
        editUser(username, password);
    }

    return (
        <div className="editAccountContainer">
            <div className="editAccountForm">
                <div className="editAccountYourEmail">Your email: {user.login}</div>
                <div className="editAccountCaption">Set login and password:</div>
                <Form
                    onFinish={submit}
                >
                    {error && error !== "no error"?
                     <div className="editAccountError">
                        {error}
                    </div>
                    :
                        <div></div>
                    }
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ]}
                    >
                        <Input
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[rules.required("Please enter password")]}
                    >
                        <Input
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type={"password"}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button size="large" type="primary" htmlType="submit" loading={isLoading}>
                            Set
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <div className="editAccountLogoutButton">
                <Button size="large" type="primary" htmlType="submit" loading={isLoading} onClick={() => {logout()}}>
                    Logout
                </Button>
            </div>
        </div>
    );
}

export default AccountComponent;