import React, {useRef} from 'react';

import './Target.css';
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";


function TargetPlaySpell({tableKey, table, side}) {

    const {phase, playedCard} = useTypedSelector(state => state.game);
    const {playCard, setPlayedCard, setPhase} = useActions();

    let targetClasses = 'tableSpellTarget';
    let showPlaySpellTarget = false;

    if(phase === 'select_spell_target'
        && playedCard.target === null
        && table[tableKey] !== 0 && table[tableKey] !== null
        && side === playedCard.card?.ability.side_target
        && (playedCard.card?.ability.aim_target === 'unit' || playedCard.card?.ability.aim_target === 'unit_castle')
    ){
        targetClasses += ' tableTargetActive';
        showPlaySpellTarget = true;
    }

    const setTargetAndSpellUnit = function(){

        playedCard.target = {key:'table_key', value:tableKey};
        playCard(playedCard);

        setPlayedCard({card:null, handKey:null, target:null});
        setPhase('play_spell');
    }

    return (
        <div className={targetClasses}>
            <div className={'clickPlaySpellTarget'}  onClick={()=>{
                if(showPlaySpellTarget){
                    setTargetAndSpellUnit();
                }
            }}></div>

        </div>
    );
}

export default TargetPlaySpell;