import React from 'react';

import './CpuResources.css';
import Resource from "../../resource/Resource";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";

function CpuResources() {

    const {cpuFire, cpuPow, cpuDiamond} = useTypedSelector(state => state.game);

    return (
        <div className={'cpuResources'}>

            <Resource cssClasses={'resource fireResource fireResourcePlayer'} resource={cpuFire} />
            <Resource cssClasses={'resource diamondResource diamondResourcePlayer'} resource={cpuDiamond} />
            <Resource cssClasses={'resource powResource powResourcePlayer'} resource={cpuPow} />


        </div>
    );
}

export default CpuResources;