import {
    GameActionEnum,
    SetCpuAction,
    SetCpuDiamondAction,
    SetCpuFireAction,
    SetCpuHpAction,
    SetCpuNameAction,
    SetCpuPlayedCardAction,
    SetCpuPowAction,
    SetCpuTableAction,
    SetEndGameAction,
    SetGameAction, SetMessageAction,
    SetPhaseAction,
    SetPlayedCardAction,
    SetPlayerADAction,
    SetPlayerDiamondAction,
    SetPlayerFireAction,
    SetPlayerHandAction,
    SetPlayerHpAction,
    SetPlayerPowAction,
    SetPlayerTableAction,
    SetShowMiddlewareAction,
    SetShowUnitAttackAction
} from "./types";

import {AppDispatch, store} from "../../index";
import {AuthActionCreators} from "../auth/action-creators";
import GameService from "../../../api/GameService";
import {IPlayedCard} from "../../../models/game/IPlayedCard";
import ServerStateDispatcher from "../../../services/states/ServerStateDispatcher";
import {SoundsService} from "../../../services/SoundsService";


export const GameActionCreators = {
    setCpu: (activeCpu): SetCpuAction => ({type: GameActionEnum.SET_CPU, payload: activeCpu}),
    setCpuName: (cpuName): SetCpuNameAction => ({type: GameActionEnum.SET_CPU_NAME, payload: cpuName}),
    setGame: (game): SetGameAction => ({type: GameActionEnum.SET_GAME, payload: game}),
    setPhase: (phase): SetPhaseAction => ({type: GameActionEnum.SET_PHASE, payload: phase}),

    setPlayerFire: (playerFire): SetPlayerFireAction => ({type: GameActionEnum.SET_PLAYER_FIRE, payload: playerFire}),
    setPlayerDiamond: (playerDiamond): SetPlayerDiamondAction => ({
        type: GameActionEnum.SET_PLAYER_DIAMOND,
        payload: playerDiamond
    }),
    setPlayerPow: (playerPow): SetPlayerPowAction => ({type: GameActionEnum.SET_PLAYER_POW, payload: playerPow}),
    setPlayerHp: (playerHp): SetPlayerHpAction => ({type: GameActionEnum.SET_PLAYER_HP, payload: playerHp}),
    setPlayerHand: (playerHand): SetPlayerHandAction => ({type: GameActionEnum.SET_PLAYER_HAND, payload: playerHand}),
    setPlayerTable: (playerTable): SetPlayerTableAction => ({
        type: GameActionEnum.SET_PLAYER_TABLE,
        payload: playerTable
    }),
    setPlayerAD: (playerAD): SetPlayerADAction => ({type: GameActionEnum.SET_PLAYER_AD, payload: playerAD}),

    setCpuFire: (cpuFire): SetCpuFireAction => ({type: GameActionEnum.SET_CPU_FIRE, payload: cpuFire}),
    setCpuDiamond: (cpuDiamond): SetCpuDiamondAction => ({type: GameActionEnum.SET_CPU_DIAMOND, payload: cpuDiamond}),
    setCpuPow: (cpuPow): SetCpuPowAction => ({type: GameActionEnum.SET_CPU_POW, payload: cpuPow}),
    setCpuHp: (cpuHp): SetCpuHpAction => ({type: GameActionEnum.SET_CPU_HP, payload: cpuHp}),
    setCpuTable: (cpuTable): SetCpuTableAction => ({type: GameActionEnum.SET_CPU_TABLE, payload: cpuTable}),

    setPlayedCard: (playedCard): SetPlayedCardAction => ({type: GameActionEnum.SET_PLAYED_CARD, payload: playedCard}),
    setCpuPlayedCard: (cpuPlayedCard): SetCpuPlayedCardAction => ({
        type: GameActionEnum.SET_CPU_PLAYED_CARD,
        payload: cpuPlayedCard
    }),
    setShowUnitAttack: (showUnitAttack): SetShowUnitAttackAction => ({
        type: GameActionEnum.SET_SHOW_UNIT_ATTACK,
        payload: showUnitAttack
    }),
    setShowMiddleware: (showMiddleware): SetShowMiddlewareAction => ({
        type: GameActionEnum.SET_SHOW_MIDDLEWARE,
        payload: showMiddleware
    }),

    setEndGame: (endGame): SetEndGameAction => ({
        type: GameActionEnum.SET_END_GAME,
        payload: endGame
    }),
    setMessage: (message): SetMessageAction => ({
        type: GameActionEnum.SET_MESSAGE,
        payload: message
    }),


    processState: async function (serverState, dispatch: AppDispatch) {

        for (let i = 0; i < Object.keys(serverState).length; i++) {

            await ServerStateDispatcher.dispatchState(serverState[i], dispatch);
        }

    },


    startGame: (cpuId: number) => async (dispatch: AppDispatch) => {
        dispatch(AuthActionCreators.setIsLoading(true));

        dispatch(GameActionCreators.setEndGame({winner: null, result: null}));

        try {
            const response = await GameService.startGame(cpuId);
            await GameActionCreators.processState(response.data, dispatch);
        } catch (e) {
            console.log('start game error');
        }

        dispatch(AuthActionCreators.setIsLoading(false));
    },

    playCard: (playedCard: IPlayedCard) => async (dispatch: AppDispatch) => {
        dispatch(AuthActionCreators.setIsLoading(true));

        SoundsService.playCard(playedCard.card, playedCard.handKey);

        try {
            const response = await GameService.playCard(playedCard);
            await GameActionCreators.processState(response.data, dispatch);
        } catch (e) {
            console.log('play card error');
        }

        dispatch(AuthActionCreators.setIsLoading(false));

        // Tutorial battle step
        const state = store.getState();
        let tutorial = state.auth.tutorial;
        if (tutorial.isActive === false) {
            return;
        }
        if (tutorial.step === 'PlayFirstCard40') {
            tutorial.step = 'EndTurnBattle45';
        }
        if (tutorial.step === 'PutUnitOnTable52') {
            tutorial.step = 'EndTurnBattle55';
        }

        AuthActionCreators.setTutorial(tutorial);
        ///
    },

    endTurn: (handKey = null) => async (dispatch: AppDispatch) => {
        dispatch(AuthActionCreators.setIsLoading(true));

        const state = store.getState();

        // Tutorial

        let tutorial = state.auth.tutorial;
        if (tutorial.isActive === true) {

            tutorial.isActive = false;
            AuthActionCreators.setTutorial(tutorial);
        }

        // tutorial end


        if (Object.keys(state.game.playerHand).length > 6 && handKey === null) {

            dispatch(GameActionCreators.setPhase('discard_card'));

            dispatch(GameActionCreators.setMessage({text: 'Discard a card!', delay: 3000, type: 'discard'}));

            dispatch(AuthActionCreators.setIsLoading(false));
            return;
        }


        dispatch(GameActionCreators.setPhase('enemy_turn'));

        try {
            const response = await GameService.endTurn(handKey);
            await GameActionCreators.processState(response.data, dispatch); // showCpuTurn
            dispatch(GameActionCreators.setPlayerAD({0: false, 1: false, 2: false, 3: false, 4: false}));
        } catch (e) {
            console.log('end turn  error');
        }

        dispatch(AuthActionCreators.setIsLoading(false));

        // Tutorial battle step
        if (tutorial.isActive === false && (tutorial.step === 'EndTurnBattle45' || tutorial.step === 'EndTurnBattle55' || tutorial.step === 'EndTurnBattle65')) {
            tutorial.isActive = true;

            if (tutorial.step === 'EndTurnBattle45') {
                tutorial.step = 'PlaySecondCard50';
            }

            if (tutorial.step === 'EndTurnBattle65') {
                tutorial.step = 'PlaySpellCard70';
            }

            if (tutorial.step === 'EndTurnBattle55') {
                tutorial.step = 'SelectAttack60';
               // tutorial.step = 'EndTurnBattle65';
            }

            AuthActionCreators.setTutorial(tutorial);
        }
        //

    }
}
