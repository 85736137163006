import React from 'react';
import './FullView.css';

// @ts-ignore
function FullView({ visible, cardId, setVisibleFullView}) {

    const rootClasses = ['researchFullViewContainer'];


    if (visible) {
        rootClasses.push('researchFullViewContainerActive');
    }
    return (
        <div className={rootClasses.join(' ')} onClick={()=>{setVisibleFullView(false)}} >

            <img  src={"/img/cards/max/" + cardId + ".png"} className='cardFullView' />
        </div>
    );
}

export default FullView;