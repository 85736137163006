import {GameActionCreators} from "../../../store/reducers/game/action-creators";
import { SoundsService } from "../../SoundsService";
import ServerStateDispatcher from "../ServerStateDispatcher";


export default class CpuPlayedCard{
    static async show(ServerState, dispatch){

        dispatch(GameActionCreators.setCpuPlayedCard({
           card: ServerState.card
        }));

        await ServerStateDispatcher.dispatchState(ServerState.result, dispatch);

        SoundsService.playCard(ServerState.card);

        await new Promise(r => setTimeout(r, 3000));

        dispatch(GameActionCreators.setCpuPlayedCard({
            card: null
        }));
    }
}