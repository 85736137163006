import './PlayerHand.css';

import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import Card from "../../../card/Card";
import PlayerHandCard from "./PlayerHandCard";


function PlayerHand() {

    const {playerHand} = useTypedSelector(state => state.game);



    return (
        <div className="PlayerHand" >
            {Object.keys(playerHand).map((item, index) => {
                return <PlayerHandCard key={'hand_'+item} card={playerHand[item]} handKey={item} />
            })}
        </div>
    );
}

export default PlayerHand;