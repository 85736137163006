import React from 'react';

import './EndTurnButton.css';
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";


function EndTurnButton() {

    const {phase, playerAD} = useTypedSelector(state => state.game);
    const {playerTable} = useTypedSelector(state => state.game);
    const {endTurn} = useActions();

    let rootClasses = 'endTurnButton';

    if (phase === 'play_card') {
        rootClasses += ' endTurnButtonActive';
    }

    let stateClasses = ' justEndTurn';


    /*
      let wall = false;

   let wall = false;

    if( table[tableKey] !== 0 && table[tableKey] !== null && table[tableKey].card.ability !== null && table[tableKey].card.ability.type_effect === 'wall'){

        wall = true;
    }
     */

    for (let i = 0; i < 5; i++) {
        if (playerAD[i] === true && playerTable[i] !== 0 && playerTable[i] !== null) {

            if (playerTable[i].sleep === true) {
                continue;
            }
            if(playerTable[i].card.ability !== null && playerTable[i].card.ability.type_effect === 'wall'){
                continue;
            }
            stateClasses = ' AttackEndTurn';
            break;
        }
    }


    return (
        <div className={rootClasses + stateClasses}
             onClick={() => {
                 if (phase === 'play_card') {
                     endTurn();
                 }
             }}
        ></div>
    );
}

export default EndTurnButton;