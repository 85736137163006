import React, { useEffect } from 'react';

import '../Tutorial.css';
import RedArrow from '../../../images/tutorial/red_arrow.png';
import FoxHead from '../../../images/tutorial/Head_fox.png';

import { useActions } from '../../../hooks/useActions';
import UserService from '../../../api/UserService';

function PlaySpellCard70() {

  useEffect(() => {
    UserService.sendEvent('PlaySpellCard70');
  }, []);

    return (
        <div>

          <div className='playCard40Top'>
          <img className='playCard40FoxHead' src={FoxHead} />
          <div className='playCard40Container HeadTextContainer' >
            <div className='HeadTextField'>Play Spell Card</div>
          </div>
          </div>
          <img className='playCard70RedArrow' src={RedArrow} />
          <div className='playCard70right'></div>
        </div>
     
    );
}

export default PlaySpellCard70;