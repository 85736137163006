import './PromoSocial.css';
import '../worldMap/BossWindow.css';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import { Button } from 'antd';

function PromoSocial({setBossWindowVisible}){
    const {setPromoSocial} = useActions();
    const {promoSocial} = useTypedSelector(state => state.auth);
    const rootClasses = ['map-popupPromo'];

    if (promoSocial) {
        rootClasses.push('map-popup-active');
    }

    return(
        <div className={rootClasses.join(' ')}>
     
        <a href="#" className="login-sign-in-close" onClick={() => {
            setPromoSocial(false);
            setBossWindowVisible(true);
        }}></a>

         <img src={"/img/promo/promoSocial.png"} className='promoImage' />
         <div className='promoButtonContainer'>
         <Button size="large" type="primary"
                    onClick={() => {
                        setPromoSocial(false);
                        setBossWindowVisible(true);
                        window.open('https://depravedworld.itch.io/game', "_blank");
                    }}
                    >
                        Yes(Itch)
                    </Button>

                    <Button size="large" type="primary"
                    onClick={() => {
                        setPromoSocial(false);
                        setBossWindowVisible(true);
                        window.open('https://discord.gg/e5U3UnehP5', "_blank");
                    }}
                    >
                        Yes!!!(Discord)
                    </Button>

                    <Button size="large" type="primary"
                    onClick={() => {
                        setPromoSocial(false);
                        setBossWindowVisible(true);
                        window.open('https://www.patreon.com/depravedworld', "_blank");
                    }}
                    >
                        YEES!!!(Patreon)
                    </Button>
         </div>
        </div>
    );

}

export default PromoSocial;