import '../Tutorial.css';
import RedArrow from '../../../images/tutorial/red_arrow.png';
import FoxHead from '../../../images/tutorial/Head_fox.png';
import UserService from '../../../api/UserService';
import { useEffect } from 'react';

function ClickShopDone105() {

  useEffect(() => {
    UserService.sendEvent('ClickShopDone105');
  }, []);

    return (
      <div>
         <img className='ShopFoxHead' src={FoxHead} />
          <div className='ShopFoxHeadContainer HeadTextContainer' >
            <div className='HeadTextField'>Press Done button</div>
          </div>   
       <div className='ClickResearch105Top'>
         <img className='ClickShop105RedArrow' src={RedArrow} />
       </div>

     
    </div>
     
    );
}

export default ClickShopDone105;