function ManaCost({costs, level}) {

    let manaCosts = costs;

    if(typeof costs === 'string'){
        manaCosts = JSON.parse(manaCosts);
    }

    return (
        <div className='manaCost'>
            {Object.keys(manaCosts).map((item, index) => {
                return <div key={index} className={'manaCostItem ' + item + 'Card ' + item + 'Card-' + level}>
                    <div>{manaCosts[item]}</div>
                </div>
            })}

        </div>
    );
}

export default ManaCost;