import {GameActionCreators} from "../../../store/reducers/game/action-creators";
import Table from "./Table";
import { sounds } from "../../../sounds/sound";

export default class UnitFight{

    static async fight(serverState, dispatch){

       
        dispatch(GameActionCreators.setShowUnitAttack({
            tableKey: serverState.tableKey,
            side: serverState.sideA
        }));

        if(serverState.sideD !== undefined) { //unit fight
          sounds.battle_unit_fight().play();
        }else{ // damage to HP
          sounds.battle_unit_deal_enemy_damage().play();
        }

        await new Promise(r => setTimeout(r, 1000));

        dispatch(GameActionCreators.setShowUnitAttack({
            tableKey: null,
            side: null
        }));

        if(serverState.sideD !== undefined) { // dont update if attack HP
            await Table.set(serverState.sideA, serverState.attacking, dispatch, serverState.tableKey);

            await Table.set(serverState.sideD, serverState.defend, dispatch, serverState.tableKey);

            await new Promise(r => setTimeout(r, 400));
        }
    }
}