import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { rules } from '../../../utils/rules';

import './DefaultLanding.css'

// @ts-ignore
const LoginPopup = ({ visible, setVisible, setLoginFrame }) => {

    const { error, isLoading } = useTypedSelector(state => state.auth);
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const { login } = useActions()

    let navigate = useNavigate();
    const submit = () => {
        login(username, password);
        navigate('/');
    }

    const rootClasses = ['sign-in-form'];


    if (visible) {
        rootClasses.push('sign-in-form-active login-frame-container');
    }

    return (
        <div className={rootClasses.join(' ')}>
         <div className='login-frame'>
            <div className="login-sign-in-close" onClick={() => { setVisible(false); setLoginFrame(true); }} ></div>
            <div className="login-sign-in-text">Sign In</div>

            <div className='login-sign-in-form'>
                <Form
                    onFinish={submit}
                >
                    {error && error !== "no error"?
                   <div style={{ color: 'red' }}>
                        {error}
                    </div>
                    :
                        <div></div>
                    }
            
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[rules.required("Please enter email!")]}
                    >
                        <Input
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[rules.required("Please enter password")]}
                    >
                        <Input
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type={"password"}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button size="large" type="primary" htmlType="submit" loading={isLoading}>
                            Sign In
                        </Button>
                    </Form.Item>
                </Form>
            </div>

         </div>

        </div>
    );
};

export default LoginPopup;