import { sounds } from "./sound"

export const SoundsCardMap = { 
  1043: function(){ return sounds.battle_play_air_mana() },
  1042: function(){ return sounds.battle_play_fire_mana() },
  1044: function(){ return sounds.battle_play_earth_mana() },


  1047: function(){ return sounds.acid_spores()},
  1027: function(){ return sounds.desperate_passion() },
  1028: function(){ return sounds.slave() },
  1029: function(){ return sounds.victim() },
  1030: function(){ return sounds.spikes_love() },
  1031: function(){ return sounds.dickfither() },
  1032: function(){ return sounds.henchman() },
  1033: function(){ return sounds.knighthood() },
  1034: function(){ return sounds.blessing() },
  1035: function(){ return sounds.horsewoman() },
  1036: function(){ return sounds.mad() },
  1037: function(){ return sounds.fire_bolt() },
  1038: function(){ return sounds.cat_girl() },
  1039: function(){ return sounds.lightning() },
  1040: function(){ return sounds.dragon_girl() },
  1041: function(){ return sounds.gabriela() },
  1046: function(){ return sounds.wall_of_fire() },
  1048: function(){ return sounds.zombie_wizard() },
  1049: function(){ return sounds.fire_elemental() },
  1050: function(){ return sounds.spark_summon() },
  1051: function(){ return sounds.blast() },
  1053: function(){ return sounds.air_elemental() },
  1054: function(){ return sounds.cloud() },
  1055: function(){ return sounds.chain_lightning() },
  1056: function(){ return sounds.snake() },
  1057: function(){ return sounds.fairy() },
  1058: function(){ return sounds.hurricane() },
  1059: function(){ return sounds.earth_elemental() },
  1060: function(){ return sounds.healing_potion() },
  1061: function(){ return sounds.sword() },
  1062: function(){ return sounds.shield() },
  1063: function(){ return sounds.troll() },
  1064: function(){ return sounds.port_rats() },
  1065: function(){ return sounds.thief() },
  1066: function(){ return sounds.kailis() },
  1067: function(){ return sounds.robbery() },
  1068: function(){ return sounds.riot() },
  1070: function(){ return sounds.lamia() },
  1069: function(){ return sounds.kailis_heretic() },
  1071: function(){ return sounds.amulet_of_death() },
  1072: function(){ return sounds.wrath_old_god() },
  1073: function(){ return sounds.chimera() },

  1045: function(){ return sounds.zombie() },
}
