import Navbar from '../componetns/navigations/Navbar';
import './MainMenu.css';
import DeckComponent from "../componetns/menu/deck/DeckComponent";
import TutorialComponent from '../componetns/tutorial/TutorialComponent';

function Deck() {
    return (
        <div className="main_menu_container">
         <Navbar/>
            <div className="main_work_field">
                <TutorialComponent />
                <DeckComponent />
            </div>
        </div>
    );
}

export default Deck;