

import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import './CpuName.css';




function CpuName() {
    const {cpuName} = useTypedSelector(state => state.game);


    return (
        <div className='cpuName'>{cpuName}</div>

);
}

export default CpuName;