export const Advices = {
    
    advicesList: [
        "To start the game, your deck must have at least 40 cards.",
        "Mana management is the basis of the game. Correct mana management will bring you closer to victory.",
        "To receive the blessing of the Goddess, you must receive more and more Pleasure.",
        "You cannot have more than 7 cards in your hand - think about what to keep and what to discard.",
        "Gabriella summons a “Zombie” card every turn. If you relax, then soon a whole immortal army will appear on the battlefield.",
        " Your deck cannot contain more than 5 cards of the same type.",
        "A large deck does not always work efficiently.",
        "Have you seen the Fairy's tail?",
        "Experiment with different decks of cards - there are more ways to win than you think.",
        "Did you know that elves love snakes?",
        "In the Dark Forest, lightning often strikes trees. Be careful - walking in a burning forest is very dangerous.",
        "Kaylis is a cunning and dexterous thief, her attacks are always deadly."
    ],



    get: function(){
      return Advices.advicesList[(Math.floor(Math.random() * Advices.advicesList.length))];
    }

}