

export const KailisStory = {
    level: 3,
    name: 'Kailis Master Thief',
    cpu1Name: 'Port Rats',
    cpu2Name: 'Thiefs',
    text: "Kailis is the head of the thieves' guild in the Free City. Since childhood, stepping onto the path of crime, she reached the peak of the criminal world. Naturally cunning and ruthless, Kailis sweeps away all her enemies along her path, often turning them into puppets who perpetually carry out the desires of the fiery beast. Kailis's lair is located in the brothel 'Fairy Haven,' and her favorite pastime is to force her defeated enemies to work off their debts in the brothel using their own bodies. No one has been able to overcome her, neither by force nor by intrigue. Kailis firmly sits on her throne as the queen of criminals.",
    cpu1: {
        id: 9,
        before: {
            type: 'comics',
            comicsSetting:{
                path: '/img/stories/kailis/intro/',
                slideNumber: 13
            }
        },
        after: {
            player: {
                type: 'text',
                step: {
                    0: "Oh, he beat innocent youths who earn hard work in the port for a piece of bread. There is someone to protect them.",
                }
            },
            cpu: {
                type: 'text',
                step: {
                    0: 'Ha! The Free City is not for the weak.',
                }
            }
        }
    },
    cpu2: {
        id: 10,
        before: {
            type: 'text',
            step: {
                0: "Let's talk like adults.",
            }
        },

        after: {
            player: {
                type: 'text',
                step: {
                    0:  "Oh, we have a new hero in town. Or just a fool who was tempted by my treasures.",
                }
            },
            cpu: {
                type: 'text',
                step: {
                    0: "As usual. Another brat got what he deserved.",
                }
            }
        },
    },
    cpu3: {
        id:11,
        before: {
            type: 'text',
            step: {
                0: "A sweet chick came for a date. There's just an opening available in the brothel for such a sweet beauty.",
            }
        },
        after: {
            player: {
                type: 'comics',
                comicsSetting:{
                    path: '/img/stories/kailis/win/',
                    slideNumber: 29
                }
            },
            cpu: {
                type: 'comics',
                comicsSetting:{
                    path: '/img/stories/kailis/lose/',
                    slideNumber: 6
                }
            }
        },
    }
}