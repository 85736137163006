import axios from "axios";
import {config} from "../config";
import GeneralService from "./GeneralService";
import {store} from "../store";
import {IPlayedCard} from "../models/game/IPlayedCard";
import { sounds } from "../sounds/sound";

export default class GameService {

    static async startGame(cpuId:number){

        return axios.post(config.serverUrl + '/api/game/cpu/start',{cpu_id:cpuId}, GeneralService.makeHeader())
    }

    static async playCard(playedCard:IPlayedCard){

        let state = store.getState();
        let data = {
            hand_key: playedCard.handKey,
            game_id: state.game.game.game_id
        };
        if(playedCard.target !== null){
            data[playedCard.target.key] = playedCard.target.value;
        }

        return axios.post(config.serverUrl + '/api/game/cpu/play-card', data, GeneralService.makeHeader())
    }

    static  async endTurn(handKey){
        let state = store.getState();
        let data = {
            player_attack: state.game.playerAD,
            game_id: state.game.game.game_id
        };

        if(handKey !== null){
            data['discard_card'] = handKey;
        }

        return axios.post(config.serverUrl + '/api/game/cpu/end-turn', data, GeneralService.makeHeader())
    }


}