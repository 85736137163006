import React, { useEffect } from 'react';

import '../Tutorial.css';
import RedArrow from '../../../images/tutorial/red_arrow.png';
import FoxHead from '../../../images/tutorial/Head_fox.png';
import UserService from '../../../api/UserService';

function EndTurnBattle() {

  useEffect(() => {
    UserService.sendEvent('EndTurnBattle');
  }, []);

    return (
        <div>

          <div className='EndTrunBattleTop'>
          <img className='playCard40FoxHead' src={FoxHead} />
          <div className='playCard40Container HeadTextContainer' >
            <div className='HeadTextField'>Press END TURN button</div>
          </div>
          <img className='EndTrunBattleRedArrow' src={RedArrow} />
          </div>
          <div className='EndTrunBattleright'></div>
          <div className='EndTrunBattleBottom'></div>

        </div>
     
    );
}

export default EndTurnBattle;