import React from 'react';

import './PlayedCard.css';

import {useTypedSelector} from "../../../hooks/useTypedSelector";
import Card from "../../card/Card";


function PlayedCard() {

    const {cpuPlayedCard, phase, playedCard} = useTypedSelector(state => state.game);

    let card = null;

    if(cpuPlayedCard.card !== null){
        // @ts-ignore
        card = cpuPlayedCard.card;
    }

    if((phase === 'select_unit_target' || phase === 'select_spell_target') && playedCard.card !== null){
        // @ts-ignore
        card = playedCard.card;
    }

    let rootClasses = 'playedCardContainer';
    if (card !== null) {
        rootClasses += ' playedCardContainerActive';
    }

    return (
        <div className={rootClasses}>
            {(card !== null)?
                <Card card={card} playedCard={true} />
                :
                <div></div>
            }
        </div>
    );
}

export default PlayedCard;