import React from 'react';

import './PlayerName.css';
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import {store} from "../../../../store";


function PlayerName() {

    const {user} = useTypedSelector(state => state.auth);
    return (
        <div className='playerName'>{user.login}</div>

);
}

export default PlayerName;