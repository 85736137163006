import React, { useEffect } from 'react';

import '../Tutorial.css';
import RedArrow from '../../../images/tutorial/red_arrow.png';
import FoxHead from '../../../images/tutorial/Head_fox.png';
import { Button } from 'antd';
import { useActions } from '../../../hooks/useActions';
import UserService from '../../../api/UserService';

function BossWindow30() {

  useEffect(() => {
    UserService.sendEvent('BossWindow30');
  }, []);


    return (
        <div>

          <div className='clickBoss20Top'>
          <img className='BossWindow30FoxHead' src={FoxHead} />
          <div className='BossWindow30HeadContainer HeadTextContainer' >
            <div className='HeadTextField'>In the chapter window, you can select an opponent. But not all at once...</div>
          </div>
          </div>
          <img className='BossWindow30RedArrow' src={RedArrow} />
        </div>
     
    );
}

export default BossWindow30;