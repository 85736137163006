import React from 'react';
import Navbar from '../componetns/navigations/Navbar';
import './MainMenu.css';
import WorldMapComponent from "../componetns/menu/worldMap/WorldMapComponent";
import { useTypedSelector } from '../hooks/useTypedSelector';
import TutorialComponent from '../componetns/tutorial/TutorialComponent';

function WorldMap() {

    const {tutorial} = useTypedSelector(state => state.auth);


    return (
        <div className="main_menu_container">
         <Navbar/>
            <div className="main_work_field">
                <TutorialComponent />
                <WorldMapComponent />
            </div>
        </div>
    );
}

export default WorldMap;