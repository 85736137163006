import '../Tutorial.css';
import RedArrow from '../../../images/tutorial/red_arrow.png';
import FoxHead from '../../../images/tutorial/Head_fox.png';
import { useEffect } from 'react';
import UserService from '../../../api/UserService';

function PlayFirstCard40() {

  useEffect(() => {
    UserService.sendEvent('PlayFirstCard40');
  }, []);


    return (
        <div>

          <div className='playCard40Top'>
          <img className='playCard40FoxHead' src={FoxHead} />
          <div className='playCard40Container HeadTextContainer' >
            <div className='HeadTextField'>Play the mana card to increase the resource gain per turn.</div>
          </div>
          </div>
          <div className='playCard40right'></div>
          <img className='playCard40RedArrow' src={RedArrow} />
        </div>
     
    );
}

export default PlayFirstCard40;