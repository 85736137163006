import {AppDispatch} from "../../store";
import {GameActionCreators} from "../../store/reducers/game/action-creators";
import CoreData from "./dispatches/CoreData";
import Resource from "./dispatches/Resource";
import HP from "./dispatches/HP";
import Table from "./dispatches/Table";
import UnitFight from "./dispatches/UnitFight";
import MiddlewareAbility from "./dispatches/MiddlewareAbility";
import CpuPlayedCard from "./dispatches/CpuPlayedCard";
import { sounds } from "../../sounds/sound";

export default class ServerStateDispatcher{
    static async dispatchState(serverState, dispatch: AppDispatch){

        // Core Data ///
        if (serverState.cpu !== undefined) {
            await CoreData.cpu(serverState.cpu, dispatch);
        }
        if (serverState.game_data !== undefined) {
            await CoreData.gameData(serverState.game_data, dispatch);
        }
        if (serverState.phase !== undefined) {
            await CoreData.phase(serverState.phase, dispatch);
        }
        if(serverState.end_game !== undefined) {
            await CoreData.endGame(serverState.end_game, dispatch);
        }

        /// Unit Fight //
        if (serverState.unit_fight !== undefined) {

            await UnitFight.fight(serverState.unit_fight, dispatch);
        }

        /// Show MiddleWare Ability
        if (serverState.middleware_ability !== undefined) {
            await MiddlewareAbility.show(serverState.middleware_ability, dispatch);
        }

        /// Player ///
        if (serverState.player !== undefined && serverState.player.resources !== undefined && serverState.player.resources.fire !== undefined) {
          await Resource.set('Player', 'Fire', serverState.player.resources.fire, dispatch);
        }
        if (serverState.player !== undefined && serverState.player.resources !== undefined && serverState.player.resources.diamond !== undefined) {
            await Resource.set('Player', 'Diamond', serverState.player.resources.diamond, dispatch);
        }
        if (serverState.player !== undefined && serverState.player.resources !== undefined && serverState.player.resources.pow !== undefined) {
            await Resource.set('Player', 'Pow', serverState.player.resources.pow, dispatch);
        }

        if (serverState.player !== undefined && serverState.player.hp !== undefined) {
            await HP.set('Player', serverState.player.hp, dispatch);
        }
        if (serverState.player !== undefined && serverState.player.hand !== undefined) {
            dispatch(GameActionCreators.setPlayerHand(serverState.player.hand));
        }
        if (serverState.player !== undefined && serverState.player.table !== undefined) {

            await Table.set('player', serverState, dispatch);
        }

        /// CPU ///
        if (serverState.cpu !== undefined && serverState.cpu.resources !== undefined && serverState.cpu.resources.fire !== undefined) {
            await Resource.set('Cpu', 'Fire', serverState.cpu.resources.fire, dispatch);
        }
        if (serverState.cpu !== undefined && serverState.cpu.resources !== undefined && serverState.cpu.resources.diamond !== undefined) {
            await Resource.set('Cpu', 'Diamond', serverState.cpu.resources.diamond, dispatch);
        }
        if (serverState.cpu !== undefined && serverState.cpu.resources !== undefined && serverState.cpu.resources.pow !== undefined) {
            await Resource.set('Cpu', 'Pow', serverState.cpu.resources.pow, dispatch);
        }
        if (serverState.cpu !== undefined && serverState.cpu.hp !== undefined) {
            await HP.set('Cpu', serverState.cpu.hp, dispatch);
        }
        if (serverState.cpu !== undefined && serverState.cpu.table !== undefined) {

            await Table.set('cpu', serverState, dispatch);
        }
        if (serverState.cpu !== undefined && serverState.cpu.name !== undefined) {

            dispatch(GameActionCreators.setCpuName(serverState.cpu.name));
        }

        /// CPU PLAYED CARD ///

        if(serverState.cpu_played_card !== undefined){

            await CpuPlayedCard.show(serverState.cpu_played_card, dispatch);

        }

        if(serverState.cpu_start_turn !== undefined){
            sounds.battle_end_turn().play();
            dispatch(GameActionCreators.setMessage({text:'Enemy turn!', delay:1500, type:'enemy_turn'}));
            await new Promise(r => setTimeout(r, 1500));

        }
        if(serverState.cpu_end_turn !== undefined){
            await new Promise(r => setTimeout(r, 500));
            sounds.battle_start_turn().play();

        }

    }
}