import React, { useEffect } from 'react';

import '../Tutorial.css';
import RedArrow from '../../../images/tutorial/red_arrow.png';
import FoxHead from '../../../images/tutorial/Head_fox.png';
import { Button } from 'antd';
import { useActions } from '../../../hooks/useActions';
import UserService from '../../../api/UserService';

function PlaySecondCard50() {

  useEffect(() => {
    UserService.sendEvent('PlaySecondCard50');
  }, []);

    return (
      <div>

      <div className='playCard40Top'>
      <img className='playCard40FoxHead' src={FoxHead} />
      <div className='playCard40Container HeadTextContainer' >
        <div className='HeadTextField'>Play Unit Card</div>
      </div>
      </div>
      <div className='playCard40right'></div>
      <img className='playCard40RedArrow' src={RedArrow} />
    </div>
     
    );
}

export default PlaySecondCard50;