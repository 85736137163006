import './Shop.css';
import {useActions} from "../../../hooks/useActions";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {message} from "antd";


function Box(box: any) {

    const {openBox, updateUser, setTutorial} = useActions();
    const [messageApi, contextHolder] = message.useMessage();
    const {user, tutorial} = useTypedSelector(state => state.auth);

    const warning = () => {
        messageApi.open({
            type: 'warning',
            content: 'Not enough gems!',
        });
    };

    return (
        <div className={"shopBoxContainer shopBox-" + box.box.id}>
            {contextHolder}

            <div className='shopBoxName'>{box.box.name}</div>

            <div className='shopBoxFooter'>
                <div className='shopBoxPrice'>{box.box.price}</div>
                <div className='shopBoxGem'></div>
                <div className='shopButtonOpen' onClick={() => {
                    if(box.box.price <= user.silver) {
                        openBox(box.box.id,  updateUser);

                        // Tutorial
                        setTimeout(() => {
                            if(tutorial.isActive === true && tutorial.step === 'ClickShop100'){
                                tutorial.step = 'ClickShopDone105';
                                setTutorial(tutorial);
                            }
                        }, 500);

                        // end

                    }else{
                        warning();
                    }

                }}></div>
            </div>
        </div>
    );
}

export default Box;