import './PlayerTable.css';

import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import Cell from "../../table/Cell";


function PlayerTable() {

    const {playerTable} = useTypedSelector(state => state.game);

    return (
        <div className='playerTable'>
            <Cell tableKey={0} table={playerTable} side={'player'}  />
            <Cell tableKey={1} table={playerTable} side={'player'} />
            <Cell tableKey={2} table={playerTable} side={'player'} />
            <Cell tableKey={3} table={playerTable} side={'player'} />
            <Cell tableKey={4} table={playerTable} side={'player'} />

        </div>
    );
}

export default PlayerTable;