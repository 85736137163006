import React, {useState} from 'react';
import {Button} from 'antd';
import './Researches.css';
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import Card from "../../card/Card";
// @ts-ignore
import starSmallImage from "../../../images/main_menu/star_small.png"
import {useActions} from "../../../hooks/useActions";
import FullView from "./FullView";


function ResearchesComponent() {

    const {user} = useTypedSelector(state => state.auth);




    const [cardIdFullView, setCardIdFullView] = useState(0);
    const [visibleFullView, setVisibleFullView] = useState(false);

    const showFullSize = function (cardId:number) {

        setCardIdFullView(cardId);
        setVisibleFullView(true);
    }


    // @ts-ignore
    return (
        <div className="research-container">

            <FullView visible={visibleFullView} cardId={cardIdFullView} setVisibleFullView={setVisibleFullView} />


            <div className="deck-filter research-filter">


            </div>
            <div className="research-card-slider">
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={10}
                    slidesPerView={6}
                    allowTouchMove={false}
                    loop={false}
                    navigation={{
                        prevEl: '.swiper-button-prev-res',
                        nextEl: '.swiper-button-next-res',
                    }}
                >

                    {Object.keys(user.library.cards).map((item, index) => {

                        return <SwiperSlide key={index}>
                            <Card key={user.library.cards[item].card.id + '_gallery'} card={user.library.cards[item].card}/>

                                <Button size="large" type="primary"  onClick={()=>{showFullSize(user.library.cards[item].card.id)}}>
                                    Full View
                                </Button>

                        </SwiperSlide>;
                    })}

                </Swiper>
                <div className="swiper-button-prev swiper-button-prev-res"></div>
                <div className="swiper-button-next swiper-button-next-res"></div>
            </div>

        </div>
    );
}

export default ResearchesComponent;