import './CpuHp.css';
import HP from "../../hp/HP";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";


function CpuHp() {

    const {cpuHp} = useTypedSelector(state => state.game);

    return (
        <div className={'cpuHP'} >
            <HP hp={cpuHp} side={'cpu'} />
        </div>
    );
}

export default CpuHp;