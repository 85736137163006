import React, { useEffect } from 'react';

import '../Tutorial.css';
import RedArrow from '../../../images/tutorial/red_arrow.png';
import FoxHead from '../../../images/tutorial/Head_fox.png';
import UserService from '../../../api/UserService';

function PutUnitOnTable52() {

  useEffect(() => {
    UserService.sendEvent('PutUnitOnTable52');
  }, []);

    return (
      <div>

  
      <img className='playCard40FoxHead' src={FoxHead} />
      <div className='playCard40Container HeadTextContainer' >
        <div className='HeadTextField'>Put unit card</div>
      </div>
     
      <div className='PutUnitOnTable52right'></div>
      <img className='PutUnitOnTable52RedArrow' src={RedArrow} />
    </div>
     
    );
}

export default PutUnitOnTable52;