import React, {useRef} from 'react';

import './Target.css';
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";


function TargetPlayUnit({tableKey, table, side}) {

    const {phase, playedCard} = useTypedSelector(state => state.game);
    const {playCard, setPlayedCard, setPhase} = useActions();

    let targetClasses = 'tableTarget';
    let showPlayUnitTarget = false;

    if(phase === 'select_unit_target' && playedCard.target === null && table[tableKey] === 0 && side === 'player'){
        targetClasses += ' tableTargetActive';
        showPlayUnitTarget = true;
    }

    const setTargetAndPlayUnit = function(){

        playedCard.target = {key:'table_key', value:tableKey};
        playCard(playedCard);

        setPlayedCard({card:null, handKey:null, target:null});
        setPhase('play_unit');
    }

    return (
        <div className={targetClasses} onClick={()=>{
            if(showPlayUnitTarget){
                setTargetAndPlayUnit();
            }
        }}> </div>
    );
}

export default TargetPlayUnit;