import '../Tutorial.css';
import RedArrow from '../../../images/tutorial/red_arrow.png';
import FoxHead from '../../../images/tutorial/Head_fox.png';
import { useEffect } from 'react';
import UserService from '../../../api/UserService';

function MenuResearch90() {

  useEffect(() => {
    UserService.sendEvent('MenuResearch90');
  }, []);

    return (
      <div>
         <img className='MenuFoxHead' src={FoxHead} />
          <div className='MenuFoxHeadContainer HeadTextContainer' >
            <div className='HeadTextField'>Press menu button</div>
          </div>   
              
       <div className='menuResearch90Bottom'>
         <img className='menuResearch90RedArrow' src={RedArrow} />
     </div>
      <div className='menuResearch90Right'></div>
      <div className='menuResearch90Left'></div>
    </div>
     
    );
}

export default MenuResearch90;