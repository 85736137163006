import React from 'react';

import './Story.css';
import {Button} from "antd";
import {useActions} from "../../../hooks/useActions";
import {useNavigate} from "react-router-dom";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import TextStoryComponent from "./TextStoryComponent";
import ComicsStoryComponent from "./ComicsStoryComponent";
import TutorialComponent from '../../tutorial/TutorialComponent';

function StoryComponent({cpuStory, endGame, setBossWindowVisible}) {


    let storyType = null;

    let comicsSetting = null;

    if (cpuStory.action === 'before') {
        storyType = cpuStory.story[cpuStory.cpuNumber][cpuStory.action].type;
        if(storyType === 'comics'){
            comicsSetting = cpuStory.story[cpuStory.cpuNumber][cpuStory.action].comicsSetting;
        }
    } else {
        storyType = cpuStory.story[cpuStory.cpuNumber][cpuStory.action][endGame.winner].type;
        if(storyType === 'comics'){
            comicsSetting = cpuStory.story[cpuStory.cpuNumber][cpuStory.action][endGame.winner].comicsSetting;
        }
    }

    const {tutorial} = useTypedSelector(state => state.auth);
    const {setTutorial} = useActions();

    const tutorialAction = function(){
        if(tutorial.step === 'ElfComics35'){
         tutorial.step = 'PlayFirstCard40';
         setTutorial(tutorial);
        }
     }

    return (
        <div className={'map-main-story-layer'}>

            {(storyType === 'text') ? (
                <TextStoryComponent cpuStory={cpuStory} endGame={endGame} setBossWindowVisible={setBossWindowVisible} tutorialAction={tutorialAction}/>
            ) : (
                <ComicsStoryComponent comicsSetting={comicsSetting} cpuStory={cpuStory} setBossWindowVisible={setBossWindowVisible} tutorialAction={tutorialAction}/>
            )

            }

            <TutorialComponent />

        </div>
    );
}

export default StoryComponent;