import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import {EffectFade, Navigation} from "swiper";
import {Button} from "antd";
import {useActions} from "../../../hooks/useActions";
import { useNavigate } from 'react-router-dom';
import { MusicService } from '../../../services/MusicService';

function ComicsStoryComponent({comicsSetting, cpuStory, setBossWindowVisible, tutorialAction}){
    const {setCpuStory, startGame} = useActions();
    let navigate = useNavigate();


    return (
        <div className={'comicsSlide'}>
            <Swiper
            
                modules={[Navigation, EffectFade]}
                spaceBetween={50}
                slidesPerView={1}
                loop={false}
                navigation={true}
                effect={'fade'}

            >
                {[...Array(comicsSetting.slideNumber)].map((x, i) =>
                    <SwiperSlide key={i} >
                        <img
                            src={comicsSetting.path + (i + 1) + ".png"}
                            alt="Project"
                            className="slide-image"
                        />
                    </SwiperSlide>

                )}


            </Swiper>

            <div className={'storySkipButton'}>
                <Button size="large" type="primary" onClick={async () => {
                   
                   if(cpuStory.action === 'before'){
                        tutorialAction();
                        await startGame(cpuStory.cpuId);
                        navigate('/game');

                        MusicService.stopTutorial();
                        MusicService.stopMenu();
                        MusicService.playBattleMusic();

                   }else{
                       setBossWindowVisible(true);
                   }
                   cpuStory.action = null;
                   setCpuStory(cpuStory);

                }}>

        {cpuStory.action === 'before' ? (
              <span>Battle</span>
            ) : (
             <span>Skip</span>
            )}

                 
                </Button>
            </div>

        </div>

    );
}

export default ComicsStoryComponent;