import React, { useEffect } from 'react';

import '../Tutorial.css';
import HotGirl from '../../../images/login/Fox_Rogue50_2.png';
import { Button } from 'antd';
import { useActions } from '../../../hooks/useActions';
import UserService from '../../../api/UserService';

function Final150() {

    const {setTutorial} = useActions();

    useEffect(() => {
        UserService.sendEvent('Final150');
      }, []);

    return (
        <div className='welcome10Container'>
            
          <img className='welcome10HotGirl' src={HotGirl} alt="" />
          <div className='tutorialBottomContainer'>
              <div className='tutorialBottomTextField'>You did well in your studies.<br/>
              Now you are ready for real adventures. I think we will see each other again.</div>
              <div className='welcome10NextButton' >
              <Button size="large" type="primary" onClick={() => {
                        setTutorial({isActive:false, step:''});
                        }}>
                            Done
                        </Button>
              </div>
          </div>
        </div>
     
    );
}

export default Final150;